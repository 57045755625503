import { ACTION } from "../../util/utils";
import { DC_REPLENISHMENT_FETCH, KEYS, MODULE_KEY } from "./action";

export const REDUCER_KEY = KEYS[MODULE_KEY + ACTION.STATE_KEY]

export interface IReplenishmentState {
    loading: boolean;
    error: any;
    data: []
}

const defaultState: IReplenishmentState = {
    loading: false,
    error: null,
    data: []
};

export const replenishmentReducer = (
    state = defaultState,
    { type, payload }: any
) => {
    switch (type) {
        case DC_REPLENISHMENT_FETCH:
            return {
                ...state,
                loading: true,
            };
        case KEYS[MODULE_KEY + ACTION.SUCCESS]:
            return {
                ...state,
                loading: false,
                data: [...payload],
            };
        case KEYS[MODULE_KEY + ACTION.FAILED]:
            return {
                ...state,
                loading: false,
                error: payload,
            };
        default:
            return state;
    }
};
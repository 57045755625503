import { Select, Space } from "antd";
import { useEffect, useState } from "react";
import { replenishmentVendorList } from "./saga";

interface VendorReplenishment {
  setVendorName: any;
}

function VendorReplenishment({
  setVendorName,
}: VendorReplenishment) {
  const { Option } = Select;
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    replenishmentVendorList({}).then((res: any) => {
      setVendors(res);
    });
  },[]);

   const menu = vendors?.map((item: any) => {
    return (
      <Option value={item.VendorName} label={item.VendorName}>
        <Space>{item.VendorName}</Space>
      </Option>
    );
  });
  return (
    <Select
      mode="multiple"
      style={{ width: "200px" }}
      placeholder="Select Vendors"
      onChange={(value) => setVendorName(value)}
      optionLabelProp="label"
      maxTagCount="responsive"
    >
      {menu}
    </Select>
  );
}

export default VendorReplenishment;

import { useSelector } from "react-redux";
import { MODULE_KEY } from "./actions";
import { ACTION, insertAt } from "src/util/utils";
import {
  ACCESSORY_REPLENISHMENT_COLUMN,
  OFFICE_COLLECTION_LISTING_COLUMN,
} from "src/util/columns";
import Button from "src/components/common/Button/Button";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ReactNode } from "react";
import ColorTag from "src/components/common/ColorTag/ColorTag";

export default function filterColumns(
  editDrawer?: ((record: any) => void) | any,
  deleteModal?: ((arg0: any) => void) | any
) {
  const { data } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const actionColumn = {
    title: "action",
    dataIndex: "operation",
    //align: "center",
    width: 100,
    render: (_: any, record: any) => {
      return (
        <div className="d-flex w-100">
          <Button
            icon={<EditOutlined />}
            children={undefined}
            onClick={() => editDrawer(record)}
          ></Button>
          <Button
            style={{ marginLeft: "20px" }}
            icon={<DeleteOutlined />}
            onClick={() => deleteModal(record)}
            children={undefined}
            danger={true}
          ></Button>
        </div>
      );
    },
  };
  let columns = insertAt(ACCESSORY_REPLENISHMENT_COLUMN, 12, 0, actionColumn);

  return { columns, data };
}

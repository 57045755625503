import { ACTION } from "src/util/utils";
import { KEYS, MODULE_KEY, KIT_FRAME_KEYS, KIT_FRAME_MODULE_KEY } from "./actions";

export const REDUCER_KEY = KEYS[MODULE_KEY + ACTION.STATE_KEY];
export const KIT_FRAME_REDUCER_KEY = KIT_FRAME_KEYS[KIT_FRAME_MODULE_KEY + ACTION.STATE_KEY];

export interface IKitsMaintenanceState {
  loading: boolean;
  error: any;
  data: [];
}

const defaultState: IKitsMaintenanceState = {
  loading: false,
  error: null,
  data: [],
};

export const kitsMaintenanceReducer = (
  state = defaultState,
  { type, payload }: any
) => {
  switch (type) {
    case KEYS[MODULE_KEY + ACTION.FETCH]:
      return {
        ...state,
        loading: true,
      };

    case KEYS[MODULE_KEY + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        data: [...payload],
      };
    case KEYS[MODULE_KEY + ACTION.FAILED]:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export interface IKitFrameState {
  loading: boolean;
  error: any;
  data: [];
}

const frameDefaultState: IKitFrameState = {
  loading: false,
  error: null,
  data: [],
};

export const kitFrameReducer = (
  state = frameDefaultState,
  { type, payload }: any
) => {
  switch (type) {
    case KIT_FRAME_KEYS[KIT_FRAME_MODULE_KEY + ACTION.FETCH]:
      return {
        ...state,
        loading: true,
      };

    case KIT_FRAME_KEYS[KIT_FRAME_MODULE_KEY + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        data: [...payload],
      };
    case KIT_FRAME_KEYS[KIT_FRAME_MODULE_KEY + ACTION.FAILED]:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

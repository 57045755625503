import {
    Divider, Spin
} from "antd";
import Breadcrumb from "src/components/common/Breadcrumb/Breadcrumb";
import TableFilter from "src/components/common/TableFilter/TableFilter";
import Button from "src/components/common/Button/Button";
import { UploadOutlined } from "@ant-design/icons";
import { useState,useEffect } from "react";
import { UploadModal } from "src/components/common/Uploads/UploadModal";
import { useDispatch, useSelector } from "react-redux";
import { MODULE_KEY, validateAcuityFrameImport } from "./actions";
import { ACTION, ImportType } from "src/util/utils";
import { ValidationModal } from "../OfficeCollection/ValidationModal";
import { showSuccessNotification } from "src/util/notifications";


function ValidateAcuityFrameImport() { 
    const [openUpload, setOpenUpload] = useState<boolean>(false);
    const [errorModal, setErrorModal] = useState(false);
  const stateKey = `${MODULE_KEY}${ACTION.STATE_KEY}`;
  const [spin, setSpin] = useState(false);
  const isImportLoading = useSelector(
    (state: any) => state[stateKey]
  ).importLoading;

    let importData = useSelector(
        (state: any) => state[stateKey]
    ).importValidationData;
    
useEffect(() => {
    if (typeof importData === "string" && importData !== null) {
      showSuccessNotification(importData);
    } else if (Object.keys(importData)?.length) {
      setErrorModal(true);
    }
}, [importData]);
  
  useEffect(() => { 
      setSpin(isImportLoading)
  }, [isImportLoading]);

    return (
      <>
        <Spin spinning={spin}>
        <Divider />
        <Breadcrumb>
          <span>Validate Acuity Frame Import</span>
            </Breadcrumb>
        <TableFilter>
          <div>
            Upload the file to be validated
            </div>
          <div className="d-flex justify-content-end">
            <Button
              size="middle"
              icon={<UploadOutlined />}
              style={{ marginRight: "10px" }}
              ant-click-animating-without-extra-node="true"
              onClick={() => setOpenUpload(true)}
            >
              {" "}
              Import
            </Button>
          </div>
            </TableFilter>
            <UploadModal
                visible={openUpload}
                onCancel={() => setOpenUpload(false)}
                stateKey={stateKey}
                importFile={validateAcuityFrameImport}
                isCsv={true}
            />
             <ValidationModal
          visible={errorModal}
          data={importData}
          onCancel={() => setErrorModal(false)}
          importType={ImportType.ValidateAcuityImport}
          />
          </Spin>
        </>
    )
};

export default ValidateAcuityFrameImport;
import { ACTION, GenericObject, actionKeyGenerate } from "src/util/utils";

export const MODULE_KEY = "EXPRESS_OFFICES";

export const KEYS: GenericObject = actionKeyGenerate(MODULE_KEY);

export const 
EXPRESS_OFFICES_FETCH = KEYS[MODULE_KEY + ACTION.FETCH];

export const fetchExpressOffice = (payload: any) => ({
  type: EXPRESS_OFFICES_FETCH,
  payload,
});

export const fetchExpressOfficeSuccess = (payload: any) => ({
  type: KEYS[MODULE_KEY + ACTION.SUCCESS],
  payload,
});

export const fetchExpressOfficeFailed = (payload: any) => ({
  type: KEYS[MODULE_KEY + ACTION.FAILED],
  payload,
});

import { showErrorNotification, showSuccessNotification } from "../../util/notifications";
import { cancelVendorOrderItems } from "./sagas";

export default async function handleCancelOrder (setConfirmLoading: any, handleCancel:any, selectedRow?: any, id?: any, itemId?: any) {
    setConfirmLoading(true);
    try {
      let payload: { ID: any; ItemID: any }[] = [];
      if (id && itemId) {
        payload.push({ ID: id, ItemID: itemId });
      } else {
        selectedRow.map((item: any, ind: number) => {
          payload.push({
            "ID": item.ID,
            "ItemID": item.ItemID
          })

        })
      }
      const res = await cancelVendorOrderItems(payload);
      if (res) {
        handleCancel();
        showSuccessNotification(res);
      } else {
        showErrorNotification(res);
      }
    } catch (error: any) {
      showErrorNotification(error.response.data.message);
    }
    setConfirmLoading(false);
  }
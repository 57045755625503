import { call, put } from "redux-saga/effects";
import {
  fetchAvailableOfficesFailed,
  fetchAvailableOfficesSuccess,
  fetchKitProcessingFailed,
  fetchKitProcessingSuccess,
  fetchSelectedOfficesFailed,
  fetchSelectedOfficesSuccess,
} from "./actions";
import { showErrorNotification } from "src/util/notifications";
import api from "src/util/api";
import {
  KITS_MAINTENANCE_URL,
  KITS_PROCESSING_URL,
  PRINT_TAGS_URL,
} from "src/util/constants/api.constants";
import { toQueryParam } from "src/util/utils";

export function* fetchKitProcessing({ payload }: any): Generator {
  try {
    let url = KITS_PROCESSING_URL.KITS_PROCESSING_LIST + payload;
    const data: any = yield call(api.get, url);
    yield put(fetchKitProcessingSuccess(data));
  } catch (e: any) {
    yield put(fetchKitProcessingFailed({ message: e }));
    showErrorNotification(null, e);
  }
}
export function* fetchAvailableOffices({ payload }: any): Generator {
  try {
    let url = PRINT_TAGS_URL.OFFICES;
    const data: any = yield call(api.post, url, payload);
    yield put(fetchAvailableOfficesSuccess(data));
  } catch (e: any) {
    yield put(fetchAvailableOfficesFailed({ message: e }));
    showErrorNotification(null, e);
  }
}
export function* fetchSelectedOffices({ payload }: any): Generator {
  try {
    let url = KITS_PROCESSING_URL.SELECTED_OFFICES;
    const data: any = yield call(api.post, url, payload);
    yield put(fetchSelectedOfficesSuccess(data));
  } catch (e: any) {
    yield put(fetchSelectedOfficesFailed({ message: e }));
    showErrorNotification(null, e);
  }
}

export function getKitData(payload: any) {
  let url = KITS_MAINTENANCE_URL.KITS_MAINTENANCE_DETAILS + payload;
  return api.get(url, {});
}

export function getKitProcessingData(payload: any) {
  let url =
    KITS_PROCESSING_URL.KITS_PROCESSING_DETAILS + "ProcessDetail/" + payload;
  return api.get(url, {});
}

export function savingKitInDraft(payload: any) {
  let url = KITS_PROCESSING_URL.KITS_PROCESSING_DETAILS + "Process";
  return api.post(url, payload);
}

export function getPrePopulatedOffices(payload: any) {
  let url = KITS_PROCESSING_URL.PRE_POPULATED_OFFICES + payload;
  return api.get(url, {});
}

export function getSelectedOffices(payload: any) {
  return api.get(KITS_PROCESSING_URL.SELECTED_OFFICES, payload);
}

export function getKitListByVendor(payload: any) {
  let url = KITS_PROCESSING_URL.KIT_BY_VENDOR + payload;
  return api.get(url, {});
}
export function validateOfficeList(payload: any) {
  let url = KITS_PROCESSING_URL.VALIDATE_OFFICES;
  return api.post(url, payload);
}

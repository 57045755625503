import { actionKeyGenerate, ACTION, GenericObject } from "../../util/utils";

export const MODULE_KEY = "FRAME_PICK";
export const PICKER = "PICKER"

export const KEYS: GenericObject = actionKeyGenerate(MODULE_KEY);
export const PICKER_KEYS: GenericObject = actionKeyGenerate(PICKER);

export const FRAME_PICK_FETCH = KEYS[MODULE_KEY + ACTION.FETCH]
export const PICKER_FETCH = PICKER_KEYS[PICKER + ACTION.FETCH]

export const fetchFramePickList = (payload: any) => ({
  type: FRAME_PICK_FETCH,
  payload,
});

export const fetchFramePickListSuccess = (payload: any) => ({
  type: KEYS[MODULE_KEY + ACTION.SUCCESS],
  payload,
});

export const fetchFramePickListFailed = (payload: any) => ({
  type: KEYS[MODULE_KEY + ACTION.FAILED],
  payload,
});

export const fetchPicker = (payload: any) => ({
  type: PICKER_KEYS[PICKER + ACTION.FETCH],
  payload,
});

export const fetchPickerSuccess = (payload: any) => ({
  type: PICKER_KEYS[PICKER + ACTION.SUCCESS],
  payload,
});

export const fetchFramePickerFailed = (payload: any) => ({
  type: PICKER_KEYS[PICKER + ACTION.FAILED],
  payload,
});


import { ACTION } from "../../util/utils";
import { VENDORS_FETCH, KEYS, MODULE_KEY } from "./vendor-actions";
import { PICKER, PICKER_KEYS } from "../framePick/actions";

export const REDUCER_KEY = KEYS[MODULE_KEY + ACTION.STATE_KEY]
export const PICKER_REDUCER_KEY = PICKER_KEYS[PICKER + ACTION.STATE_KEY]

export interface IVendorState {
  loading: boolean;
  error: any;
  data: [];
}

const defaultState: IVendorState = {
  loading: false,
  error: null,
  data: [],
};

export interface IPickerState {
  loading: boolean;
  error: any;
  data: [];
}

const pickerDefaultState: IPickerState = {
  loading: false,
  error: null,
  data: [],
};

export const vendorReducer = (
  state = defaultState,
  { type, payload }: any
) => {
  switch (type) {
    case KEYS[MODULE_KEY + ACTION.FETCH]:
      return {
        ...state,
        loading: true,
      };
      
    case KEYS[MODULE_KEY + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
      };
    case KEYS[MODULE_KEY + ACTION.FAILED]:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export const pickerReducer = (
  state = pickerDefaultState,
  { type, payload }: any
) => {
  switch (type) {
    case PICKER_KEYS[PICKER + ACTION.FETCH]:
      return {
        ...state,
        loading: true,
      };
      
    case PICKER_KEYS[PICKER + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...payload],
      };
    case PICKER_KEYS[PICKER + ACTION.FAILED]:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

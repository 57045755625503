import { loginRequest, msalInstance } from '../config/authConfig';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { notification } from 'antd';

class AuthService {
  async refreshAccessToken() {
    const account = msalInstance.getAllAccounts()[0];
    if(account.homeAccountId) {
      try {
        const { accessToken } = await msalInstance.acquireTokenSilent({
          account,
          scopes: loginRequest.scopes,
        });
        return accessToken;
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          return msalInstance
            .acquireTokenPopup(loginRequest)
            .then((resp) => {
              return resp.accessToken;
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          notification.error({
            message: 'Error',
            className: 'toast-container error',
            description: 'Your session has expired, please sign in',
            duration: 0,
          });
          // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logout.md
          await msalInstance.logoutRedirect({
            onRedirectNavigate: (url) => {
              // Return false if you would like to stop navigation after local logout
              return false;
            },
          });
        }
      }
    }
  }
}

export default new AuthService();

import { ACTION, GenericObject, actionKeyGenerate } from "src/util/utils";

export const MODULE_KEY = "VALIDATE_ACUITY_IMPORT";

export const VALIDATE_ACUITY_IMPORT_FILE = "VALIDATE_ACUITY_IMPORT_FILE";
export const VALIDATE_ACUITY_IMPORT_FILE_SUCCESS = "VALIDATE_ACUITY_IMPORT_FILE_SUCCESS";
export const VALIDATE_ACUITY_IMPORT_FILE_FAILED = "VALIDATE_ACUITY_IMPORT_FILE_FAILED";

export const VALIDATE_ACUITY_CLEAN_IMPORT_LAYOUT = "VALIDATE_ACUITY_CLEAN_IMPORT_LAYOUT";
export const VALIDATE_ACUITY_IMPORT = "VALIDATE_ACUITY_IMPORT";

export const KEYS: GenericObject = actionKeyGenerate(MODULE_KEY);

export const validateAcuityFrameImport = (payload: any) => ({
  type:VALIDATE_ACUITY_IMPORT_FILE,
  payload,
});

export const validateAcuityFrameImportSuccess = (payload: any) => ({
  type: VALIDATE_ACUITY_IMPORT_FILE_SUCCESS,
  payload,
});

export const validateAcuityFrameImportFailed = (payload: any) => ( {
  type: VALIDATE_ACUITY_IMPORT_FILE_FAILED,
  payload,
});

export const cleanImportLayout = () => ({
  type: VALIDATE_ACUITY_CLEAN_IMPORT_LAYOUT,
});

export const updateValidateImport = (
  payload: any,
  actionType: any,
  flag: any
) => ({
  type: VALIDATE_ACUITY_IMPORT,
  payload,
  actionType,
  flag,
});

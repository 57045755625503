import { ACTION } from "src/util/utils";
import { CLEAN_IMPORT_LAYOUT, IMPORT_FILE, IMPORT_FILE_FAILED, IMPORT_FILE_SUCCESS, KEYS, MODULE_KEY, UPDATE_VALIDATED_IMPORT, UPDATE_VALIDATED_IMPORT_FAILED, UPDATE_VALIDATED_IMPORT_SUCCESS } from "./actions";

export const REDUCER_KEY = KEYS[MODULE_KEY + ACTION.STATE_KEY];

export interface IOfficeCollectionMappingState {
  loading: boolean;
  error: any;
  data: [];
  importLoading: boolean;
  importFailed: boolean;
  importValidationData: any[];
}

const defaultState: IOfficeCollectionMappingState = {
  loading: false,
  error: null,
  data: [],
  importLoading: false,
  importFailed: false,
  importValidationData: [],
};

export const officeCollectionMappingReducer = (
  state = defaultState,
  { type, payload }: any
) => {
  switch (type) {
    case KEYS[MODULE_KEY + ACTION.FETCH]:
      return {
        ...state,
        loading: true,
      };

    case KEYS[MODULE_KEY + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        data: [...payload],
      };
    case KEYS[MODULE_KEY + ACTION.FAILED]:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case IMPORT_FILE: {
      return {
        ...state,
        importLoading: true,
      };
    }
    case IMPORT_FILE_SUCCESS: {
      return {
        ...state,
        importLoading: false,
        importFailed: false,
        importValidationData: payload,
      };
    }
    case IMPORT_FILE_FAILED: {
      return {
        ...state,
        importLoading: false,
        importFailed: true,
        importValidationData: payload,
      };
    }
    case UPDATE_VALIDATED_IMPORT: {
      return {
        ...state,
        importLoading: true,
      };
    }
    case UPDATE_VALIDATED_IMPORT_SUCCESS:
    case UPDATE_VALIDATED_IMPORT_FAILED: {
      return {
        ...state,
        importLoading: false,
      };
    }
    case CLEAN_IMPORT_LAYOUT: {
      return {
        ...state,
        importLoading: false,
        importFailed: false,
        importValidationData: [],
      };
    }

    default:
      return state;
  }
};

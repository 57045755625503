import { actionKeyGenerate, ACTION, GenericObject } from "../../util/utils";

export const MODULE_KEY = "OPEN_PULL";

export const KEYS : GenericObject = actionKeyGenerate(MODULE_KEY)

export const OPEN_PULL_FETCH = KEYS[MODULE_KEY+ACTION.FETCH]

export const fetchOpenPullList = (payload: any) => ({
    type: OPEN_PULL_FETCH,
    payload,
  });
  
  export const fetchOpenPullListSucces = (payload: any) => ({
    type: KEYS[MODULE_KEY+ACTION.SUCCESS],
    payload,
  });
  
  export const fetchOpenPullListFailed = (payload: any) => ({
    type: KEYS[MODULE_KEY+ACTION.FAILED],
    payload,
  });

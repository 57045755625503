import { call, put } from "redux-saga/effects";
import {
  fetchKitFrameFailed,
  fetchKitFrameSuccess,
  fetchKitsMaintenanceFailed,
  fetchKitsMaintenanceSuccess,
} from "./actions";
import { showErrorNotification } from "src/util/notifications";
import api from "src/util/api";
import { KITS_MAINTENANCE_URL } from "src/util/constants/api.constants";
import { toQueryParam } from "src/util/utils";

export function* fetchKitsMaintenance({ payload }: any): Generator {
  try {
    let url = KITS_MAINTENANCE_URL.KITS_MAINTENANCE_LIST + payload;
    const data: any = yield call(api.get, url);
    yield put(fetchKitsMaintenanceSuccess(data));
  } catch (e: any) {
    yield put(fetchKitsMaintenanceFailed({ message: e }));
    showErrorNotification(null, e);
  }
}
export function* fetchKitFrame({ payload }: any): Generator {
  try {
    let url = KITS_MAINTENANCE_URL.KITS_MAINTENANCE_DETAILS;
    const data: any = yield call(api.post, url, payload);
    yield put(fetchKitFrameSuccess(data));
  } catch (e: any) {
    yield put(fetchKitFrameFailed({ message: e }));
    showErrorNotification(null, e);
  }
}

export function getKitData(payload: any) {
  let url = KITS_MAINTENANCE_URL.KITS_MAINTENANCE_DETAILS + payload;
  return api.get(url, {});
}

export function checkUPC(payload: any) {
  let url = KITS_MAINTENANCE_URL.CHECK_UPC_VENDOR_COLLECTION;
  return api.post(url, payload);
}

export function saveKit(payload: any) {
  let url = KITS_MAINTENANCE_URL.KITS_MAINTENANCE;
  return api.post(url, payload);
}
export function getCollectionByVendor(payload: any) {
  let url = KITS_MAINTENANCE_URL.COLLECTION_BY_VENDOR + payload;
  return api.get(url, {});
}

export function deleteKit(payload: any) {
  let url = `${KITS_MAINTENANCE_URL.DELETE_KIT}`;
  return api.delete(url, payload);
}

export function archiveKit(payload: any) {
  let url = `${KITS_MAINTENANCE_URL.ARCHIVE_KIT}`;
  return api.post(url, payload);
}

import { actionKeyGenerate, ACTION, GenericObject } from "../../util/utils";

export const MODULE_KEY = "ORDER_STATUS";
export const PENDING_ORDER_MODULE_KEY = "PENDING_ORDER";

export const KEYS: GenericObject = actionKeyGenerate(MODULE_KEY);
export const PENDING_ORDER_KEYS: GenericObject = actionKeyGenerate(PENDING_ORDER_MODULE_KEY);

export const VENDOR_ORDER_FETCH = KEYS[MODULE_KEY + ACTION.FETCH]
export const PENDING_ORDER_FETCH = PENDING_ORDER_KEYS[PENDING_ORDER_MODULE_KEY + ACTION.FETCH]


export const fetchVendorOrders = (payload: any) => ({
    type: VENDOR_ORDER_FETCH,
    payload,
  });
  
  export const fetchVendorOrderSuccess = (payload: any) => ({
    type: KEYS[MODULE_KEY + ACTION.SUCCESS],
    payload,
  });
  
  export const fetchVendorOrderFailed = (payload: any) => ({
    type: KEYS[MODULE_KEY + ACTION.FAILED],
    payload,
  });

  export const fetchPendingOrders = (payload: any) => ({
    type: PENDING_ORDER_FETCH,
    payload,
  });
  
  export const fetchPendingOrderSuccess = (payload: any) => ({
    type: PENDING_ORDER_KEYS[PENDING_ORDER_MODULE_KEY + ACTION.SUCCESS],
    payload,
  });
  
  export const fetchPendingOrderFailed = (payload: any) => ({
    type: PENDING_ORDER_KEYS[PENDING_ORDER_MODULE_KEY + ACTION.FAILED],
    payload,
  });
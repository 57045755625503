import { useSelector } from "react-redux";
import { ACTION, insertAt } from "src/util/utils";
import Button from "src/components/common/Button/Button";
import { DeleteOutlined } from "@ant-design/icons";
import { EXPRESS_OFFICE_COLUMN } from "src/util/columns";
import { MODULE_KEY } from "./actions";
import moment from "moment";

export default function filterColumns(
  deleteModal?: ((record: any) => void) | any
) {
  const { data } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );

  const actionColumn = {
    title: "action",
    dataIndex: "operation",
    align: "center",
    render: (_: any, record: any) => {
      return (
        <Button
          icon={<DeleteOutlined />}
          onClick={() => deleteModal(record?.LocationNumber)}
          children={undefined}
          danger={true}
        ></Button>
      );
    },
  };
  let columns = insertAt(EXPRESS_OFFICE_COLUMN, 10, 0, actionColumn);

  return { columns };
}

import { Card, Form, Input, Drawer, Space, Spin } from "antd";
import { useDispatch } from "react-redux";
import { useInputs } from "../../hooks/useInput";
import { useValidation, ValidationRule } from "../../hooks/useValidation";
import { showErrorNotification, showSuccessNotification } from "../../util/notifications";
import { GenericObject, hasValue } from "../../util/utils";
import Button from '../common/Button/Button';
import { fetchPicker } from "../framePick/actions";
import { addUpdatePicker } from "./vendor-sagas";

interface AddPickerDataType {
  visible: boolean
  pickerList:any
  loading: boolean
  setVisible : any
}

function AddPickerForm({visible, pickerList, loading, setVisible} : AddPickerDataType) {
    const defaultData = {};
    const Rules = {
      EmployeeId: (new ValidationRule()).isRequired(),
      PickerName: (new ValidationRule()).isRequired(),
    }
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [inputs, onInputChange,, resetInputs] = useInputs({ defaultData });
    const errors: GenericObject = useValidation(inputs, Rules);
    const handleClose = () => {
        setVisible(false);
        form.resetFields();
        resetInputs();
      };
    const handleOk = async () => {
        if (!(pickerList.findIndex((p: { PickerName: any; }) => p.PickerName == inputs.PickerName) != -1)) {
          try {
            const res = await addUpdatePicker(inputs);
            if (res) {
              handleClose();
              showSuccessNotification(res);
              dispatch(fetchPicker({}));
            } else {
              showErrorNotification(res);
            }
          } catch (error: any) {
            showErrorNotification(error.response.data);
          }
        } else {
          showErrorNotification("Picker Name has already exist");
          // resetInputs();
        }
      };
    return (
        <>
            <Drawer
                title="Add Picker"
                placement="right"
                visible={visible}
                onClose={handleClose}
                width={400}
                closable={false}
                className="dc-drawer-panel"
                forceRender={true}
                extra={
                    <Space>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleOk} disabled={hasValue(errors) || !inputs.EmployeeId || !inputs.PickerName} >
                            Save
                        </Button>
                    </Space>
                }
            >
                <Spin spinning={loading}>
                    <Form
                        form={form}
                        initialValues={{ remember: false }}
                        autoComplete="off"
                        layout='vertical'
                        className='add-picker-form'
                        style={{
                            backgroundColor: "#f3f3f3",
                            borderRadius: "16px",
                            marginRight: "24px",
                            boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)"
                        }}
                    >
                        <Card>
                            <Form.Item validateStatus={errors["EmployeeId"] && "error"} help={errors["employeeId"]} label="Employee ID " name="EmployeeID" className='label-text input-field' rules={[{ pattern: new RegExp(/\d+/g), required: true, message: "EmployeeID should be numeric" }]}>
                                <Input name="EmployeeId" onChange={onInputChange} />
                            </Form.Item>
                            <Form.Item validateStatus={errors["PickerName"] && "error"} help={errors["PickerName"]} label="Picker Name" name="PickerName" className='label-text input-field' rules={[{ required: true }]}>
                                <Input name="PickerName" onChange={onInputChange} />
                            </Form.Item>
                        </Card>
                    </Form>
                </Spin>
            </Drawer>
        </>
    )
}

export default AddPickerForm;

import { DatePicker, Divider, Form, Input, Select, Spin } from "antd";
import "./../../styles/vendor.scss";
import { ORDER_STATUS_COLUMN } from "../../util/columns";
import GenericTable from "../common/Table/GenericTable";
import { MODULE_KEY, fetchOrderStatus } from "./actions";
import TableFilter from "../common/TableFilter/TableFilter";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../common/Button/Button";
import Breadcrumb from "../common/Breadcrumb/Breadcrumb";
import { fetchPicker } from "../framePick/actions";

function OrderStatus() {
  const { Option } = Select;
  let memorizeDate = moment().format("YYYY-MM-DD");
  const [ordernumber, setOrderNumber] = useState<any>("");
  const [username, setUsername] = useState<any>("");
  const [orderdate, setOrderDate] = useState<any>(memorizeDate);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { picker } = useSelector((state: any) => state["FRAME_PICK_STATE_KEY"]);
  const unAssignedPicker = picker?.filter(
    (a: { PickerName: string }) => a.PickerName === "Unassigned"
  );
  const remainingPicker = picker?.filter(
    (a: { PickerId: number; PickerName: string }) =>
      a.PickerName !== "Unassigned" && a.PickerId !== 0
  );
  const pickerArr = remainingPicker?.sort(
    (a: { PickerName: string }, b: { PickerName: string }) => {
      return a.PickerName.localeCompare(b.PickerName);
    }
  );
  const finalPickerArr = [...unAssignedPicker, ...pickerArr];
  const [form] = Form.useForm();
  useEffect(() => {
    dispatch(fetchPicker({}));
  }, []);
  useEffect(() => {
    onLoad();
  }, []);
  const handleData = () => {
    setLoader(true);
    if (username || ordernumber || orderdate) {
      onLoad();
    }
    setLoader(false);
  };
  const handleDate = (date: any) => {
    memorizeDate = date?.format("YYYY-MM-DD");
    setOrderDate(memorizeDate);
  };
  const onLoad = () => {
    let data: any = {
      offset: 0,
      limit: 10000,
      orderDate: orderdate,
    };
    if (username) {
      data = { ...data, pickerid: username };
    }
    if (ordernumber) {
      data = { ...data, ordernumber: ordernumber };
    }
    dispatch(fetchOrderStatus(data));
  };
  const handleReset = () => {
    memorizeDate = moment().format("YYYY-MM-DD");
    setOrderDate(memorizeDate);
    setOrderNumber("");
    setUsername("");
    form.resetFields();
    let data: any = {
      offset: 0,
      limit: 10000,
      orderDate: memorizeDate,
    };
    dispatch(fetchOrderStatus(data));
  };

  const handleOrderNumber = (
    oNum: any,
    OrderDate: string,
    UserName: string
  ) => {
    setOrderDate("");
    setUsername("");
    form.setFieldsValue({ [OrderDate]: "", [UserName]: "" });
    setOrderNumber(oNum.target.value);
  };
  return (
    <>
      <Spin tip="Loading..." spinning={loader}>
        <Divider />
        <Breadcrumb>
          <span>View Orders</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end">
            <Form form={form} name="order" onFinish={handleData}>
              <div style={{ display: "flex" }}>
                <Form.Item
                  name="OrderDate"
                  initialValue={orderdate && moment(orderdate)}
                >
                  <DatePicker
                    value={orderdate && moment(orderdate)}
                    disabledDate={(d) => !d || d.isAfter(moment())}
                    onChange={handleDate}
                    className="search-field-size"
                    style={{ width: 120 }}
                    placeholder="Order Date"
                    size="middle"
                  />
                </Form.Item>
                <Form.Item name="UserName">
                  <Select
                    placeholder="Select"
                    showSearch
                    value={username}
                    style={{ width: 120, marginLeft: 10 }}
                    optionFilterProp="children"
                    size="middle"
                    onSelect={(val: any) => {
                      setUsername(val);
                    }}
                  >
                    {finalPickerArr?.map(({ PickerId, PickerName }: any) => {
                      return (
                        <Option key={PickerId} value={PickerId}>
                          {PickerName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="OrderNumber"
                  rules={[
                    {
                      pattern: new RegExp(/^[0-9]+$/),
                      message: `Order number should be numeric`,
                    },
                  ]}
                >
                  <Input
                    placeholder="Order Number"
                    style={{ marginLeft: 10, width: 120 }}
                    className="search-field-size"
                    value={ordernumber}
                    onChange={(val) =>
                      handleOrderNumber(val, "OrderDate", "UserName")
                    }
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="submit"
                    size="middle"
                    style={{ marginLeft: 20 }}
                  >
                    Get Order Details
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button
                    htmlType="button"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    onClick={handleReset}
                    size="middle"
                  >
                    Reset
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
        </TableFilter>
        <GenericTable
          rowkey="OrderNumber"
          columns={ORDER_STATUS_COLUMN}
          pagination={{
            defaultPageSize: 100,
            showSizeChanger: true,
            pageSizeOptions: ["100", "500", "1000", "3000", "5000"],
          }}
          moduleKey={MODULE_KEY}
          loading={loader}
          xScroll={1000}
          yScroll={"calc(80vh - 17.5em)"}
          form={form}
        />
      </Spin>
    </>
  );
}

export default OrderStatus;

import { ACTION } from "../../util/utils";
import { KEYS, MODULE_KEY, OFFICE_LOCATION, OFFICE_LOCATION_KEYS } from "./printTags-actions";

export const REDUCER_KEY = KEYS[MODULE_KEY + ACTION.STATE_KEY]
export const OFFICE_LOCATION_REDUCER_KEY = OFFICE_LOCATION_KEYS[OFFICE_LOCATION + ACTION.STATE_KEY]

//export const PRINT_TAGS_STATE_KEY = "PRINT_TAGS_REDUCER";

export interface IPrintTagsState {
  loading: boolean;
  error: any;
  officeLocationList: [];
  data: []
}

const defaultState: IPrintTagsState = {
  loading: false,
  error: null,
  officeLocationList: [],
  data: []
};

export const printTagsReducer = (
  state = defaultState,
  { type, payload }: any
) => {
  switch (type) {
    case OFFICE_LOCATION_KEYS[OFFICE_LOCATION + ACTION.FETCH]:
      return {
        ...state,
        loading: true,
      };
    case OFFICE_LOCATION_KEYS[OFFICE_LOCATION + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        officeLocationList: [...payload],
      };
    case OFFICE_LOCATION_KEYS[OFFICE_LOCATION + ACTION.FAILED]:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case KEYS[MODULE_KEY + ACTION.FETCH]:
      return {
        ...state,
        loading: true,
      };
    case KEYS[MODULE_KEY + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        data: [...payload],
      };
    case KEYS[MODULE_KEY + ACTION.FAILED]:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

import { ACTION, GenericObject, actionKeyGenerate } from "src/util/utils";

export const MODULE_KEY = "KITS_PROCESSING";
export const AVAILABLE_OFFICE_MODULE_KEY = "AVAILABLE_OFFICE";
export const SELECTED_OFFICE_MODULE_KEY = "SELECTED_OFFICE";

export const KEYS: GenericObject = actionKeyGenerate(MODULE_KEY);
export const AVAILABLE_OFFICE_KEYS: GenericObject = actionKeyGenerate(
  AVAILABLE_OFFICE_MODULE_KEY
);
export const SELECTED_OFFICE_KEYS: GenericObject = actionKeyGenerate(
  SELECTED_OFFICE_MODULE_KEY
);

export const KITS_PROCESSING_FETCH = KEYS[MODULE_KEY + ACTION.FETCH];
export const AVAILABLE_OFFICE_FETCH =
  AVAILABLE_OFFICE_KEYS[AVAILABLE_OFFICE_MODULE_KEY + ACTION.FETCH];
export const SELECTED_OFFICE_FETCH =
SELECTED_OFFICE_KEYS[SELECTED_OFFICE_MODULE_KEY + ACTION.FETCH];

export const fetchKitProcessing = (payload: any) => ({
  type: KITS_PROCESSING_FETCH,
  payload,
});

export const fetchKitProcessingSuccess = (payload: any) => ({
  type: KEYS[MODULE_KEY + ACTION.SUCCESS],
  payload,
});

export const fetchKitProcessingFailed = (payload: any) => ({
  type: KEYS[MODULE_KEY + ACTION.FAILED],
  payload,
});

export const fetchAvailableOffices = (payload: any) => ({
  type: AVAILABLE_OFFICE_FETCH,
  payload,
});

export const fetchAvailableOfficesSuccess = (payload: any) => ({
  type: AVAILABLE_OFFICE_KEYS[AVAILABLE_OFFICE_MODULE_KEY + ACTION.SUCCESS],
  payload,
});

export const fetchAvailableOfficesFailed = (payload: any) => ({
  type: AVAILABLE_OFFICE_KEYS[AVAILABLE_OFFICE_MODULE_KEY + ACTION.FAILED],
  payload,
});

export const fetchSelectedOffices = (payload: any) => ({
  type: SELECTED_OFFICE_FETCH,
  payload,
});

export const fetchSelectedOfficesSuccess = (payload: any) => ({
  type: SELECTED_OFFICE_KEYS[SELECTED_OFFICE_MODULE_KEY + ACTION.SUCCESS],
  payload,
});

export const fetchSelectedOfficesFailed = (payload: any) => ({
  type: SELECTED_OFFICE_KEYS[SELECTED_OFFICE_MODULE_KEY + ACTION.FAILED],
  payload,
});
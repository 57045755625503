import React from 'react'

interface Props {
    children: React.ReactNode
    label: string
    className?: string
    props?: any
    labelProps?: any
    style?:any

}

function Label({ children, className="", props = {}, labelProps = {}, label, style }: Props) {
    return (
        <section className={`label-wrapper ${className}`} {...props} >
            <label {...labelProps} style={style}>{label}</label>
            {children}
        </section>
    )
}

export default Label
import { Card, Divider } from "antd";
import TableFilter from "src/components/common/TableFilter/TableFilter";
import { DATE_TIME_FORMAT } from "src/util/columns";
import moment from "moment";
import SIGN_IN_LOGO from "../../assets/images/sign-in-logo.svg";
import styles from "./Dashboard.module.scss";

function Dashboard() {
  return (
    <>
      <Divider />
      {/* <Breadcrumb>
          <span></span>
        </Breadcrumb> */}
      <TableFilter>
        <div className="form align-items-end flex">
          <h5>
            <strong>Current Date : </strong> {moment().format(DATE_TIME_FORMAT)}
          </h5>
        </div>
      </TableFilter>
      <Card style={{ width: 1000, textAlign: "center", margin: "20px auto" }}>
        <img src={SIGN_IN_LOGO} width={120} height={120} />
        <h2>Welcome to Our Distribution Center!</h2>
      </Card>
      <img alt="logo" className={styles["logo"]} src={"./images/logo.svg"} />
    </>
  );
}
export default Dashboard;

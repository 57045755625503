import { Divider, Form, Modal, Popconfirm, Spin } from "antd";
import {
  MODULE_KEY,
  fetchOfficeCollectionMapping,
  importFile,
} from "./actions";
import GenericTable from "src/components/common/Table/GenericTable";
import TableFilter from "src/components/common/TableFilter/TableFilter";
import {
  DownOutlined,
  DownloadOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Breadcrumb from "src/components/common/Breadcrumb/Breadcrumb";
import Button from "src/components/common/Button/Button";
import { useEffect, useState } from "react";
import filterColumns from "./filterColumns";
import "./../../../styles/vendor.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOPlanogram,
  getFADetail,
  getFrameCollectionForOffice,
} from "./sagas";
import AddOCMapping from "./AddOCMapping";
import { UploadModal } from "src/components/common/Uploads/UploadModal";
import { ACTION } from "src/util/utils";
import { ValidationModal } from "./ValidationModal";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import moment from "moment";
import { OFFICE_COLLECTION_EXCEL_COLUMNS } from "src/util/columns";
const ExcelJS = require("exceljs");
import { ImportType } from "src/util/utils";
import { useMsal } from "@azure/msal-react";
import { set } from "lodash";

function OfficeCollectionMapping() {
  const dispatch = useDispatch();
  const { accounts }: any = useMsal();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [openUpload, setOpenUpload] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [frameCollection, setFrameCollection] = useState();
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [deleteData, setDeleteData] = useState(false);

  const stateKey = `${MODULE_KEY}${ACTION.STATE_KEY}`;

  let importData = useSelector(
    (state: any) => state[stateKey]
  ).importValidationData;
  const [errorModal, setErrorModal] = useState(false);
  const [filteredData, setFilteredData] = useState<any>([]);
  const closeModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  };
  useEffect(() => {
    dispatch(fetchOfficeCollectionMapping({}));
  }, []);
  const addDrawer = (e: React.MouseEvent<HTMLButtonElement>) => {
    setVisible(true);
  };
  const editDrawer = (record: any) => {
    let payload;
    payload = [record?.ID];
    setVisible(true);

    const res = getFADetail(payload);
    res.then((response: any) => {
      setModalData(response);
    });
    const frames = getFrameCollectionForOffice(payload);
    frames.then((response: any) => {
      setFrameCollection(response);
    });
  };
  const deleteModal = (record: any) => {
    setVisibleDelete(true);
    setDeleteData(record);
  };

  const handleCancel = () => {
    setVisible(false);
    dispatch(fetchOfficeCollectionMapping({}));
  };

  let filterValues = filterColumns(editDrawer, deleteModal);
  useEffect(() => {
    try {
      if (typeof importData === "string") {
        showSuccessNotification(importData);
      } else if (Object.keys(importData)?.length) {
        setErrorModal(true);
      }
      dispatch(fetchOfficeCollectionMapping({}));
    } catch (error: any) {
      if (error && error?.response) {
        if (error?.response?.data) {
          showErrorNotification(error.response.data.error.messages[0]);
        } else {
          showErrorNotification(error.response);
        }
      }
    }
  }, [importData]);
  const exportExcelFile = async () => {
    setConfirmLoading(true);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet("My Sheet");
    //sheet.properties.defaultRowHeight = 25;

    sheet.columns = OFFICE_COLLECTION_EXCEL_COLUMNS;
    const data =
      filteredData?.length > 0
        ? filteredData
        : filteredData[0] !== "none"
        ? filterValues?.data
        : [];
    try {
      if (data?.length > 0) {
        setOpen(false);
        setConfirmLoading(false);
        let color;
        const promise = Promise.all(
          data?.map(async (item: any, index: number) => {
            const rowNumber = index + 2;
            sheet.addRow({
              OfficeNum: item.OfficeNum,
              LocationName: item.LocationName,
              Vendor: item.Vendor,
              Collection: item.Collection,
              CollectionType: item.CollectionType,
              FrameArrangerID: item.FrameArrangerID,
              ArrangerQty: item.ArrangerQty,
              UpdatedDate:
                item.UpdatedDate !== null
                  ? moment(item.UpdatedDate).format("YYYY-MM-DD")
                  : "",
              UpdateNotes: item.UpdateNotes,
              AssortmentName: item.AssortmentName,
              StopDate:
                item.StopDate !== null
                  ? moment(item.StopDate).format("YYYY-MM-DD")
                  : "",
            });
          })
        );

        promise.then(() => {
          workbook.xlsx.writeBuffer().then(function (res: BlobPart) {
            const blob = new Blob([res], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "Office-Collection-Report.csv";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          showSuccessNotification("Excel downloaded successfully");
        });
      } else {
        setOpen(false);
        setConfirmLoading(false);
        showErrorNotification("No data available for the selected date");
      }
    } catch (error: any) {
      setOpen(false);
      setConfirmLoading(false);
      showErrorNotification(error.response.data.error.messages[0]);
    }
  };

  const handleDelete = () => {
    setConfirmLoading(true);
    const payload = {
      ID: deleteData,
      CreatedBy: accounts?.[0]?.username,
    };
    deleteOPlanogram(payload)
      .then((res: any) => {
        showSuccessNotification(res);
        handleCancel();
        setConfirmLoading(false);
      })
      .catch((error: any) => {
        showErrorNotification(error.response.data.error.messages[0]);
        setConfirmLoading(false);
      });

    setVisibleDelete(false);
  };
  return (
    <>
      <Spin spinning={confirmLoading}>
        <Divider />
        <Breadcrumb>
          <span>Office Planograms</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end flex"></div>
          <div className="d-flex justify-content-end">
            <Button
              size="middle"
              icon={<DownloadOutlined />}
              style={{ marginRight: "10px" }}
              ant-click-animating-without-extra-node="true"
              onClick={closeModal}
              disabled={filteredData[0] === "none"}
            >
              {" "}
              Export
            </Button>
            <Button
              size="middle"
              icon={<UploadOutlined />}
              style={{ marginRight: "10px" }}
              ant-click-animating-without-extra-node="true"
              onClick={() => setOpenUpload(true)}
            >
              {" "}
              Import
            </Button>
            <Button
              size="middle"
              icon={<PlusOutlined />}
              style={{ marginRight: "10px" }}
              ant-click-animating-without-extra-node="true"
              onClick={addDrawer}
            >
              {" "}
              Add New
            </Button>
          </div>
        </TableFilter>
        <GenericTable
          rowkey="ID"
          columns={filterValues.columns}
          moduleKey={MODULE_KEY}
          loading={confirmLoading}
          xScroll={1000}
          yScroll={"calc(90vh - 17.5em)"}
          pagination={{
            defaultPageSize: 500,
            showSizeChanger: true,
            pageSizeOptions: ["10", "50", "100", "500", "1000"],
          }}
          filteredData={true}
          setFilteredData={setFilteredData}
        />
        <AddOCMapping
          visible={visible}
          setVisibleStatus={setVisible}
          onLoad={handleCancel}
          data={modalData}
          setData={setModalData}
          frameCollection={frameCollection}
          confirmLoading={confirmLoading}
          setConfirmLoading={setConfirmLoading}
        />
        <UploadModal
          visible={openUpload}
          onCancel={() => setOpenUpload(false)}
          stateKey={stateKey}
          importFile={importFile}
        />
        <ValidationModal
          visible={errorModal}
          data={importData}
          onCancel={() => setErrorModal(false)}
          importType={ImportType.OfficeCollectionImport}
        />
        <Modal
          title="Excel export Confirmation"
          okText="Ok"
          open={open}
          centered
          confirmLoading={confirmLoading}
          style={{ top: 20 }}
          onOk={() => exportExcelFile()}
          onCancel={() => setOpen(false)}
        >
          <span>Are you sure you want to export this data?</span>
        </Modal>
        <Modal
          title="Delete Confirmation"
          okText="Ok"
          centered
          confirmLoading={confirmLoading}
          style={{ top: 20 }}
          open={visibleDelete}
          onOk={handleDelete}
          onCancel={() => setVisibleDelete(false)}
        >
          <span>
            Are you sure you wish to delete this office collection mapping?
          </span>
        </Modal>
      </Spin>
    </>
  );
}
export default OfficeCollectionMapping;

import React from "react";
import NavbarMenu from "../navbar/NavbarMenu";
import styled from "styled-components";
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import Profile from "./profile/Profile";

export default function Header({ routes }: any) {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const userName = (accounts && accounts.length && accounts[0]?.name) || "";
  let nonProdEnv: any = "";
  if (window.location.hostname !== "dcapp.myeyedr.com") {
    nonProdEnv = <StyledENV>NON-PRODUCTION ENVIRONMENT</StyledENV>;
  }

  return (
    <>
      <StyledHeader>
        <AuthenticatedTemplate>
          <StyledHeading>
            <h4>Distribution Center</h4>
            {nonProdEnv}
          </StyledHeading>
          <div>{userName}</div>
        </AuthenticatedTemplate>
        <StyledProfile />
      </StyledHeader>
      <NavbarMenu routes={routes} />
    </>
  );
}

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  position: relative;
  padding: 4px 0;
  height: 40px;
  margin-bottom: 10px;
  margin-top: 10px;
`;
const StyledProfile = styled(Profile)`
  align-self: flex-end;
`;

const StyledHeading = styled.div`
  left: 20px;
  position: absolute;
  top: 1px;
  display: flex;
  align-items: center;
  & h4 {
    line-height: 1.7;
    margin: 0;
    font-weight: bold;
  }
`;
const StyledENV = styled.h3`
  color: #d14d4d;
  left: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding: 4px 0;
  height: 40px;
`;

import { ACTION } from "../../util/utils";
import { DAILY_REPORT_KEYS, DAILY_REPORT_MODULE_KEY, KEYS, MODULE_KEY, VENDOR_EXLUSION_KEYS, VENDOR_EXLUSION_MODULE_KEY } from "./actions";

export const REDUCER_KEY = KEYS[MODULE_KEY + ACTION.STATE_KEY]
export const DAILY_REPORT_REDUCER_KEY = DAILY_REPORT_KEYS[DAILY_REPORT_MODULE_KEY + ACTION.STATE_KEY]
export const VENDOR_EXLUSION_REDUCER_KEY = VENDOR_EXLUSION_KEYS[VENDOR_EXLUSION_MODULE_KEY + ACTION.STATE_KEY]

export interface IOrderStatusState {
  loading: boolean;
  error: any;
  data: [];
}

const defaultState: IOrderStatusState = {
  loading: false,
  error: null,
  data: [],
};

export const orderStatusReducer = (
  state = defaultState,
  { type, payload }: any
) => {
  switch (type) {
    case KEYS[MODULE_KEY + ACTION.FETCH]:
      return {
        ...state,
        loading: true,
      };
      
    case KEYS[MODULE_KEY + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        data: [...payload],
      };
    case KEYS[MODULE_KEY + ACTION.FAILED]:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export interface IDailyReportState {
  loading: boolean;
  error: any;
  data: [];
}

const dailyReportState: IDailyReportState = {
  loading: false,
  error: null,
  data: [],
};

export const dailyReportReducer = (
  state = dailyReportState,
  { type, payload }: any
) => {
  switch (type) {
    case DAILY_REPORT_KEYS[DAILY_REPORT_MODULE_KEY + ACTION.FETCH]:
      return {
        ...state,
        loading: true,
      };
      
    case DAILY_REPORT_KEYS[DAILY_REPORT_MODULE_KEY + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        data: [...payload],
      };
    case DAILY_REPORT_KEYS[DAILY_REPORT_MODULE_KEY + ACTION.FAILED]:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export interface IVendorExclusionState {
  loading: boolean;
  error: any;
  data: [];
}

const vendorExclusionState: IVendorExclusionState = {
  loading: false,
  error: null,
  data: [],
};

export const vendorExclusionReducer = (
  state = dailyReportState,
  { type, payload }: any
) => {
  switch (type) {
    case VENDOR_EXLUSION_KEYS[VENDOR_EXLUSION_MODULE_KEY + ACTION.FETCH]:
      return {
        ...state,
        loading: true,
      };
      
    case VENDOR_EXLUSION_KEYS[VENDOR_EXLUSION_MODULE_KEY + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        data: [...payload],
      };
    case VENDOR_EXLUSION_KEYS[VENDOR_EXLUSION_MODULE_KEY + ACTION.FAILED]:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};



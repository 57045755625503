import { call, put } from "redux-saga/effects";
import api from "../../util/api";
import { REPLENISHMENT_URL } from "../../util/constants/api.constants";
import { toQueryParam } from "../../util/utils";
import {
  fetchReplenishmentListFailed,
  fetchReplenishmentListSuccess,
} from "./action";

export function* fetchReplenishment({ payload }: any): Generator {
  try {
    let url = REPLENISHMENT_URL.REPLENISHMENT_LIST;
    const data: any = yield call(api.post, url, payload);
    yield put(fetchReplenishmentListSuccess(data));
  } catch (e: any) {
    put(fetchReplenishmentListFailed({ message: e.message }));
  }
}

export function replenishmentApprove(payload: any) {
  let url = REPLENISHMENT_URL.REPLENISHMENT_APPROVE;
  return api.post(url, payload);
}

export function replenishmentRefresh(payload: any) {
  let url = REPLENISHMENT_URL.REPLENISHMENT_REFRESH;
  return api.post(url, payload);
}

export function replenishmentVendorList(payload: any) {
  let url = REPLENISHMENT_URL.REPLENISHMENT_VENDOR_LIST;
  return api.get(url, payload);
}

export function updateOrderQty(payload: any) {
  let url = REPLENISHMENT_URL.OVERRIDE_ORDER_QTY_URL;
  return api.put(url, payload);
}

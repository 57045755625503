import { useState } from "react";
import styled from "styled-components";
import { Drawer, Space, Descriptions } from "antd";
import { ProfileButton, ProfileIcon } from "./Profile";
import { CloseIcon } from "../../common/icons";

const ProfilePanel = ({ user, onSignOut }: any) => {
  const [open, setOpen] = useState<boolean>(false);
  let role: any = [];
  user?.idTokenClaims?.roles?.map((item: any) => {
    role.push(item.charAt(0).toUpperCase() + item.slice(1).toLowerCase());
  });
  role = role.join(",");
  return (
    <>
      <ProfileButton
        type="primary"
        shape="circle"
        icon={<ProfileIcon src={"./icons/avatar.svg"} alt="avatar" />}
        onClick={() => setOpen(true)}
      />
      <StyledDrawer
        visible={open}
        closable={false}
        onClose={() => {
          setOpen(false);
        }}
        title={user?.name || ""}
        extra={
          <div>
            <Space>
              <CloseIcon onClick={() => setOpen(false)} />
            </Space>
          </div>
        }
      >
        <Descriptions column={1}>
          <Descriptions.Item label="Email">{user.username}</Descriptions.Item>
          <Descriptions.Item label="Role">{role}</Descriptions.Item>
        </Descriptions>
      </StyledDrawer>
    </>
  );
};

export default ProfilePanel;

const StyledDrawer = styled(Drawer)`
  & .ant-drawer-body {
    padding: 25px;
  }

  & .ant-list-item {
    display: flex;
    font-size: 12px;
    align-items: center;
  }
`;

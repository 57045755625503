import { useSelector } from "react-redux";
import { ACTION, insertAt } from "../../util/utils";
import { DAILY_REPORT_MODULE_KEY } from "./actions";
import { DAILY_REPORT_COLUMNS } from "../../util/columns";

export default function filterColumns() {
    const { data } = useSelector((state: any) => state[`${DAILY_REPORT_MODULE_KEY}${ACTION.STATE_KEY}`]);
    const collectionName: any | null | undefined = [];
    const vendors: any | null | undefined = [];
    const regions: any | null | undefined = [];
    const districts: any | null | undefined = [];
    const upcCodes: any | null | undefined = [];
    const items: any | null | undefined = [];
    const labs: any | null | undefined = [];
    const offices:any | null | undefined = [];
    data?.map((row: any) => {
        collectionName.push({ text: row.FrameCollection, value: row.FrameCollection });
        vendors.push({ text: row.VendorName, value: row.VendorName });
        regions.push({ text: row.Region, value: row.Region });
        districts.push({ text: row.District, value: row.District });
        upcCodes.push({ text: row.UPCCode, value: row.UPCCode });
        items.push({ text: row.ItemName, value: row.ItemName });
        labs.push({ text: row.LabStatus, value: row.LabStatus });
        offices.push({ text: row.OfficeName, value: row.OfficeName });
    });
    let frameCollectionName = collectionName.filter((ele: any, ind: any) => ind === collectionName.findIndex((elem: any) => elem.text === ele.text && elem.value !== ''))
    let vendorName = vendors.filter((ele: any, ind: any) => ind === vendors.findIndex((elem: any) => elem.text === ele.text && elem.value !==''))
    let regionName = regions.filter((ele: any, ind: any) => ind === regions.findIndex((elem: any) => elem.text === ele.text && elem.value !== ''))
    let DistrictName = districts.filter((ele: any, ind: any) => ind === districts.findIndex((elem: any) => elem.text === ele.text && elem.value !== ''))
    let upcCodeName = upcCodes.filter((ele: any, ind: any) => ind === upcCodes.findIndex((elem: any) => elem.text === ele.text && elem.value !== ''))
    let itemName = items.filter((ele: any, ind: any) => ind === items.findIndex((elem: any) => elem.text === ele.text && elem.value !== ''))
    let labstatus = labs.filter((ele: any, ind: any) => ind === labs.findIndex((elem: any) => elem.text === ele.text && elem.value !== null))
    let officesName = offices.filter((ele: any, ind: any) => ind === offices.findIndex((elem: any) => elem.text === ele.text && elem.value !== null))
    const collectionColumns = {
        title: "FrameCollection",
        dataIndex: "FrameCollection",
        sorter: (a: { FrameCollection: string; }, b: { FrameCollection: any; }) => a.FrameCollection?.localeCompare(b.FrameCollection),
        sortDirections: ["ascend", "descend"],
        filters: frameCollectionName,
        onFilter: (value: any, record: { FrameCollection: string | any[]; }) => record.FrameCollection.indexOf(value) === 0,
        width: 150
    };
    const vendorColumns = {
        title: "Vendor Name",
        dataIndex: "VendorName",
        sorter: (a: { VendorName: string; }, b: { VendorName: any; }) => a.VendorName?.localeCompare(b.VendorName),
        sortDirections: ["ascend"],
        filters: vendorName,
        onFilter: (value: any, record: { VendorName: string | any[]; }) => record.VendorName.indexOf(value) === 0,
        width: 150
    };
    const regionColumns = {
        title: "Region",
        dataIndex: "Region",
        filters: regionName,
        onFilter: (value: any, record: { Region: string | any[]; }) => record.Region.indexOf(value) === 0,
        width: 100
    };
    const districtColumns = {
        title: "District",
        dataIndex: "District",
        filters: DistrictName,
        onFilter: (value: any, record: { District: string | any[]; }) => record.District.indexOf(value) === 0,
        width: 100
    };
    const upcCodeColumns = {
        title: "UPC code",
        dataIndex: "UPCCode",
        filterSearch: true,
        width: 150
    };
    const itemNameColumns = {
        title: "Item Name",
        dataIndex: "ItemName",
        filters: itemName,
        onFilter: (value: any, record: { ItemName: string | any[]; }) => record.ItemName.indexOf(value) === 0,
        width: 150
    };
    const officesColumn = {
        title: "Office Name",
        dataIndex: "OfficeName",
        filters: officesName,
        onFilter: (value: any, record: { OfficeName: string | any[]; }) => record.OfficeName.indexOf(value) === 0,
        width: 150
    };

    
    let columns = insertAt(DAILY_REPORT_COLUMNS, 3, 0, regionColumns);
    columns = insertAt(columns, 4, 0, districtColumns);
    columns = insertAt(columns, 6, 0, officesColumn);
    columns = insertAt(columns, 8, 0, vendorColumns);
    columns = insertAt(columns, 9, 0, collectionColumns);
    columns = insertAt(columns, 10, 0, upcCodeColumns);

    return {columns, data};

}
import { ACTION, GenericObject, actionKeyGenerate } from "src/util/utils";

export const MODULE_KEY = "VENDOR_COLLECTION_MAPPING";

export const KEYS: GenericObject = actionKeyGenerate(MODULE_KEY);

export const VENDOR_COLLECTION_MAPPING_FETCH = KEYS[MODULE_KEY + ACTION.FETCH]

export const fetchVendorCollectionMapping = (payload: any) => ({
    type: VENDOR_COLLECTION_MAPPING_FETCH,
    payload,
  });
  
  export const fetchVendorCollectionMappingSuccess = (payload: any) => ({
    type: KEYS[MODULE_KEY + ACTION.SUCCESS],
    payload,
  });
  
  export const fetchVendorCollectionMappingFailed = (payload: any) => ({
    type: KEYS[MODULE_KEY + ACTION.FAILED],
    payload,
  });

  
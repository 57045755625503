import {  useState } from "react";

const { stringify, parse } = JSON;
export function useInputs(defaultInputs: any) {
    const [inputs, setInputs] = useState(defaultInputs)

    const updateValue = (key: string, value: any) => {
        const newValues = parse(stringify(inputs));
        newValues[key] = value;
        setInputs(newValues);
    }
    const inputHandler = (event: any) => {
        const { name, value } = event.target;
        updateValue(name, value)
    }

    const reset = () => {
        setInputs(defaultInputs);
    }

    return [parse(stringify(inputs)), inputHandler, updateValue, reset]

}
import { useEffect, useMemo, useState } from 'react';
import validator from 'validator';
import { GenericObject } from '../util/utils';
import _ from 'lodash';

const { isEmpty } = validator;
const MESSAGE = {
    REQUIRED: `{{}} field is required`
}

export class ValidationRule {
    error: GenericObject = {};
    data: GenericObject = {};
    validation: any = [];
    setData(data: any) {
        if (Object.keys(data).length) {
            this.data = data;
        }
        return this;
    }
    getError(key:string) {
       return this.error[key];
    }

    isRequired(preMessage?: string) {
        const required = (key: string, message = preMessage || MESSAGE.REQUIRED) => {
            const value = this.data[key];
            const length = isEmpty(value);
            this.error[key] = length ? message.replace("{{}}", key) : "";

            return !length;
        }
        this.validation.push(required)
        return this;
    }

    execute(key:string) {
        for (let i = 0; i < this.validation.length; i++) {
            const ele = this.validation[i](key);
            if (!ele) {
                
                break;
            }
        }
        return this;
    }

}


export function useValidation(data: any, rules: any) {
    const [errors, setErrors] = useState({});
    const [oldData, setOldData] = useState(data)

    useEffect(() => {
        if ( !_.isEqual(data, oldData)) {
            const changes : GenericObject = {}
            for (const key in data) {
                const element = data[key];
                if (element != oldData[key]) {
                    const rule = rules[key];
                    if (rule) {
                        
                        changes[key] = rule.setData(data).execute(key).getError(key);
                    }
                }
            }
            setErrors({...errors,...changes});
            setOldData(data);
        }

    }, [data]);
   
    return errors;
}
import { actionKeyGenerate, ACTION, GenericObject } from "../../util/utils";

export const MODULE_KEY = "DC_REPLENISHMENT";

export const KEYS: GenericObject = actionKeyGenerate(MODULE_KEY);

export const DC_REPLENISHMENT_FETCH = KEYS[MODULE_KEY + ACTION.FETCH]

export const fetchReplenishmentList = (payload: any) => ({
    type: DC_REPLENISHMENT_FETCH,
    payload,
});

export const fetchReplenishmentListSuccess = (payload: any) => ({
    type: KEYS[MODULE_KEY + ACTION.SUCCESS],
    payload,
});

export const fetchReplenishmentListFailed = (payload: any) => ({
    type: KEYS[MODULE_KEY + ACTION.FAILED],
    payload,
});

import { useSelector } from "react-redux";
import { MODULE_KEY } from "./action";
import { ACTION, insertAt } from "../../util/utils";
import { DC_REPLENISHMENT_COLUMN } from "../../util/columns";

export default function filterColumns() {
  const { data } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const collectionName: any | null | undefined = [];
  const vendors: any | null | undefined = [];
  const orders: any | null | undefined = [];
  data?.map((row: any) => {
    collectionName.push({ text: row.Collection, value: row.Collection });
    vendors.push({ text: row.VendorName, value: row.VendorName });
    orders.push({ text: row.OrderID, value: row.OrderID });
  });
  let frameCollectionName = collectionName.filter(
    (ele: any, ind: any) =>
      ind === collectionName.findIndex((elem: any) => elem.text === ele.text)
  );
  let vendorName = vendors.filter(
    (ele: any, ind: any) =>
      ind === vendors.findIndex((elem: any) => elem.text === ele.text)
  );
  let orderIds = orders.filter(
    (ele: any, ind: any) =>
      ind ===
      orders.findIndex(
        (elem: any) => elem.text === ele.text && elem.text !== ""
      )
  );
  const collectionColumns = {
    title: "Collection",
    dataIndex: "Collection",
    sorter: (a: { Collection: string }, b: { Collection: any }) =>
      a.Collection?.localeCompare(b.Collection),
    sortDirections: ["ascend", "descend"],
    filters: frameCollectionName,
    onFilter: (value: any, record: { Collection: string | any[] }) =>
      record.Collection.indexOf(value) === 0,
    width: 150,
  };
  const vendorColumns = {
    title: "Vendor Name",
    dataIndex: "VendorName",
    sorter: (a: { VendorName: string }, b: { VendorName: any }) =>
      a.VendorName?.localeCompare(b.VendorName),
    sortDirections: ["ascend"],
    filters: vendorName,
    onFilter: (value: any, record: { VendorName: string | any[] }) =>
      record.VendorName.indexOf(value) === 0,
    width: 150,
  };
  const orderIdColumns = {
    title: "Order Id",
    dataIndex: "OrderID",
    filters: orderIds,
    onFilter: (value: any, record: { OrderID: string | any[] }) =>
      record.OrderID.indexOf(value) === 0,
    align: "center",
    width: 100,
  };
  let columns = insertAt(DC_REPLENISHMENT_COLUMN, 2, 0, vendorColumns);
  columns = insertAt(columns, 3, 0, collectionColumns);
  columns = insertAt(columns, 4, 0, orderIdColumns);

  return { columns, vendorName, data };
}

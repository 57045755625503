import { ACTION, GenericObject, actionKeyGenerate } from "src/util/utils";

export const MODULE_KEY = "PRODUCT_PLANOGRAM";
export const PRODUCT_PLANOGRAM_IMPORT_FILE = "PRODUCT_PLANOGRAM_IMPORT_FILE";
export const PRODUCT_PLANOGRAM_IMPORT_FILE_SUCCESS = "PRODUCT_PLANOGRAM_IMPORT_FILE_SUCCESS";
export const PRODUCT_PLANOGRAM_IMPORT_FILE_FAILED = "PRODUCT_PLANOGRAM_IMPORT_FILE_FAILED";

export const PRODUCT_PLANOGRAM_CLEAN_IMPORT_LAYOUT = "PRODUCT_PLANOGRAM_CLEAN_IMPORT_LAYOUT";
export const PRODUCT_PLANOGRAM_UPDATE_VALIDATED_IMPORT = "PRODUCT_PLANOGRAM_UPDATE_VALIDATED_IMPORT";
export const PRODUCT_PLANOGRAM_UPDATE_VALIDATED_IMPORT_SUCCESS =
  "PRODUCT_PLANOGRAM_UPDATE_VALIDATED_IMPORT_SUCCESS";
export const PRODUCT_PLANOGRAM_UPDATE_VALIDATED_IMPORT_FAILED = "PRODUCT_PLANOGRAM_UPDATE_VALIDATED_IMPORT_FAILED";

export const KEYS: GenericObject = actionKeyGenerate(MODULE_KEY);

export const PRODUCT_PLANOGRAM_FETCH = KEYS[MODULE_KEY + ACTION.FETCH]

export const fetchProductPlanogramMapping = (payload: any) => (
  {
    type: PRODUCT_PLANOGRAM_FETCH,
    payload,
  });
  
  export const fetchProductPlanogramMappingSuccess = (payload: any) => ({
    type: KEYS[MODULE_KEY + ACTION.SUCCESS],
    payload,
  });
  
  export const fetchProductPlanogramMappingFailed = (payload: any) => ({
    type: KEYS[MODULE_KEY + ACTION.FAILED],
    payload,
});
  
export const productPlanogramImportFile = (payload: any) => ({
  type: PRODUCT_PLANOGRAM_IMPORT_FILE,
  payload,
});

export const importFileSuccess = (payload: any) => ({
  type: PRODUCT_PLANOGRAM_IMPORT_FILE_SUCCESS,
  payload,
});

export const importFileFailed = (payload: any) => ({
  type: PRODUCT_PLANOGRAM_IMPORT_FILE_FAILED,
  payload,
});

export const cleanImportLayout = () => ({
  type: PRODUCT_PLANOGRAM_CLEAN_IMPORT_LAYOUT,
});

export const updateValidatedImport = (
  payload: any,
  actionType: any,
  flag: any
) => ({
  type: PRODUCT_PLANOGRAM_UPDATE_VALIDATED_IMPORT,
  payload,
  actionType,
  flag,
});

  
import { ACTION, GenericObject, actionKeyGenerate } from "src/util/utils";

export const MODULE_KEY = "OFFICE_COLLECTION_MAPPING";
export const IMPORT_FILE = "IMPORT_FILE";
export const IMPORT_FILE_SUCCESS = "IMPORT_FILE_SUCCESS";
export const IMPORT_FILE_FAILED = "IMPORT_FILE_FAILED";

export const CLEAN_IMPORT_LAYOUT = "CLEAN_IMPORT_LAYOUT";
export const UPDATE_VALIDATED_IMPORT = "UPDATE_VALIDATED_IMPORT";
export const UPDATE_VALIDATED_IMPORT_SUCCESS =
  "UPDATE_VALIDATED_IMPORT_SUCCESS";
export const UPDATE_VALIDATED_IMPORT_FAILED = "UPDATE_VALIDATED_IMPORT_FAILED";

export const KEYS: GenericObject = actionKeyGenerate(MODULE_KEY);

export const OFFICE_COLLECTION_MAPPING_FETCH = KEYS[MODULE_KEY + ACTION.FETCH];

export const fetchOfficeCollectionMapping = (payload: any) => ({
  type: OFFICE_COLLECTION_MAPPING_FETCH,
  payload,
});

export const fetchOfficeCollectionMappingSuccess = (payload: any) => ({
  type: KEYS[MODULE_KEY + ACTION.SUCCESS],
  payload,
});

export const fetchOfficeCollectionMappingFailed = (payload: any) => ({
  type: KEYS[MODULE_KEY + ACTION.FAILED],
  payload,
});

export const importFile = (payload: any) => ({
  type: IMPORT_FILE,
  payload,
});

export const importFileSuccess = (payload: any) => ({
  type: IMPORT_FILE_SUCCESS,
  payload,
});

export const importFileFailed = (payload: any) => ({
  type: IMPORT_FILE_FAILED,
  payload,
});

export const cleanImportLayout = () => ({
  type: CLEAN_IMPORT_LAYOUT,
});

export const updateValidatedImport = (
  payload: any,
  actionType: any,
  flag: any
) => ({
  type: UPDATE_VALIDATED_IMPORT,
  payload,
  actionType,
  flag,
});

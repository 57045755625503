import React, { useMemo, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { PrinterOutlined, DownloadOutlined } from '@ant-design/icons';
import { saveAs } from "file-saver";
import Button from "../Button/Button";
import moment from "moment";
var toUint8Array = require('base64-to-uint8array')


function PDFStream({ stream, fileName="FrameLabels", onPrint = () => { } }) {
  const arr = useMemo(() => toUint8Array(stream), [stream])

  const printHandler = () => {
    const blob = new Blob([arr]);
    const date = moment().format("DD-MM-YYYY");
    saveAs(blob, fileName + "-" + date + ".pdf");
    onPrint();
  }

  return (
    <div >
      <Button onClick={printHandler} icon={<DownloadOutlined />} >
      Download PDF
      </Button>
      <div id="pdf-print"  >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.8.335/build/pdf.worker.min.js" Readonly>
          <Viewer fileUrl={arr} />
        </Worker>
      </div>
      <Button onClick={printHandler} icon={<DownloadOutlined />} >
      Download PDF
      </Button>
    </div>
  )
}

export default PDFStream
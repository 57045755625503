import { Checkbox } from "antd";
import { ReactNode } from "react";
import {
  DATE_FORMAT,
  FRAME_PICK_COLUMN,
  FRAME_PICK_DATA_TYPE,
} from "../../util/columns";
import { useSelector } from "react-redux";
import { MODULE_KEY } from "./actions";
import { ACTION, insertAt } from "../../util/utils";
import moment from "moment";

interface filterColumns {
  selectedRow: any;
  setSelectedRow: any;
  notFoundRow: any;
  setNotFoundRow: any;
}
export default function filterColumns({
  selectedRow,
  setSelectedRow,
  notFoundRow,
  setNotFoundRow,
}: filterColumns) {
  const notFoundCheckHandler = (
    selectedRow: any,
    notFoundSelectedRow: any,
    checked: boolean
  ) => {
    if (checked) {
      setSelectedRow(
        selectedRow.filter(
          (row: FRAME_PICK_DATA_TYPE) =>
            row.OrderNum != notFoundSelectedRow.OrderNum
        )
      );
      setNotFoundRow([...notFoundRow, notFoundSelectedRow]);
    } else {
      setNotFoundRow(
        notFoundRow.filter(
          (row: FRAME_PICK_DATA_TYPE) =>
            row.OrderNum != notFoundSelectedRow.OrderNum
        )
      );
    }
  };

  const notFoundColumn = {
    title: "Not Found",
    dataIndex: "OrderNum",
    align: "center",
    render: (OrderNum: string, row: any): ReactNode => {
      return (
        <Checkbox
          disabled={row?.Status?.trim()}
          checked={notFoundRow.find(
            (notFoundSelectedRow: FRAME_PICK_DATA_TYPE) =>
              notFoundSelectedRow.OrderNum == OrderNum
          )}
          onChange={(e: any) =>
            notFoundCheckHandler(selectedRow, row, e.target.checked)
          }
        />
      );
    },
  };
  const { picker, data } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const collectionName: any | null | undefined = [];
  const orDate: any | null | undefined = [];
  data?.map((row: any) => {
    collectionName.push({
      text: row.FrameCollection,
      value: row.FrameCollection,
    });
    orDate.push({
      text: moment(row.OrderDate).format(DATE_FORMAT),
      value: row.OrderDate,
    });
  });
  let frameCollectionName = collectionName.filter(
    (ele: any, ind: any) =>
      ind === collectionName.findIndex((elem: any) => elem.text === ele.text)
  );
  let orderDate = orDate.filter(
    (ele: any, ind: any) =>
      ind === orDate.findIndex((elem: any) => elem.text === ele.text)
  );

  const frameCollection = {
    title: "Frame Collection",
    dataIndex: "FrameCollection",
    filters: frameCollectionName,
    onFilter: (value: any, record: { FrameCollection: string | any[] }) =>
      record.FrameCollection.indexOf(value) === 0,
    sorter: (a: { FrameCollection: string }, b: { FrameCollection: any }) =>
      a.FrameCollection?.localeCompare(b.FrameCollection),
    sortDirections: ["descend"],
    width: 150,
  };

  const orderDateColumn = {
    title: "Order Date",
    dataIndex: "OrderDate",
    sorter: (a: { OrderDate: any }, b: { OrderDate: any }) =>
      moment(a.OrderDate || 0).unix() - moment(b.OrderDate || 0).unix(),
    sortDirections: ["ascend", "descend"],
    render: (text: string) => {
      return text ? moment(text).format(DATE_FORMAT) : null;
    },
    width: 100,
    filters: orderDate,
    onFilter: (value: any, record: { OrderDate: string | any[] }) =>
      record.OrderDate.indexOf(value) === 0,
  };

  let columns = insertAt(FRAME_PICK_COLUMN, 2, 0, orderDateColumn);
  columns = insertAt(columns, 3, 0, frameCollection);
  columns = insertAt(columns, 4, 0, notFoundColumn);

  const unAssignedPicker = picker?.filter(
    (a: { PickerName: string }) => a.PickerName === "Unassigned"
  );
  const remainingPicker = picker?.filter(
    (a: { PickerName: string }) => a.PickerName !== "Unassigned"
  );
  const pickerArr = remainingPicker?.sort(
    (a: { PickerName: string }, b: { PickerName: string }) => {
      return a.PickerName.localeCompare(b.PickerName);
    }
  );
  const finalPickerArr = [...unAssignedPicker, ...pickerArr];

  return {
    data,
    columns,
    finalPickerArr,
  };
}

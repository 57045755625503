import { call, put } from "redux-saga/effects";
import {
  fetchDailyReportFailed,
  fetchDailyReportSuccess,
  fetchOrderStatusFailed,
  fetchOrderStatusSuccess,
} from "./actions";
import { default as api } from "../../util/api";
import { showErrorNotification } from "../../util/notifications";
import {
  DAILY_REPORT_URL,
  ORDER_STATUS_URL,
} from "../../util/constants/api.constants";
import { toQueryParam } from "../../util/utils";

export function* fetchOrderStatus({ payload }: any): Generator {
  try {
    let url = toQueryParam(ORDER_STATUS_URL.ORDER_STATUS_LIST, payload);
    const data: any = yield call(api.get, url);
      yield put(fetchOrderStatusSuccess(data));
  } catch (e: any) {
    yield put(fetchOrderStatusFailed({ message: e.message }));
  }
}

export function* fetchDailyReport({ payload }: any): Generator {
  try {
    let url = toQueryParam(DAILY_REPORT_URL.DAILY_REPORT_LIST, payload);
    const data: any = yield call(api.get, url);
    yield put(fetchDailyReportSuccess(data));
  } catch (e: any) {
    yield put(fetchDailyReportFailed({ message: e }));
    showErrorNotification(e.response?.data?.error?.messages[0]);
  }
}

export function fetchDailyExcelReport(payload: any) {
  return api.get(DAILY_REPORT_URL.DAILY_REPORT_LIST, payload);
}

export function updateOrderStatus(payload: any) {
  return api.post(DAILY_REPORT_URL.UPDATE_ORDER_STATUS, payload);
}

export function* fetchVendorExclusion({ payload }: any): Generator {
  try {
    let url = toQueryParam(DAILY_REPORT_URL.VENDOR_EXLUSION_LIST, payload);
    const data: any = yield call(api.get, url);
    yield put(fetchDailyReportSuccess(data));
  } catch (e: any) {
    fetchDailyReportFailed({ message: e.message });
  }
}

export function getExcludedVendors(payload: any) {
  return api.get(DAILY_REPORT_URL.VENDOR_EXLUSION_LIST, payload);
}

export function saveExcludedVendors(payload: any) {
  return api.post(DAILY_REPORT_URL.SAVE_VENDOR_EXLUSION, payload);
}

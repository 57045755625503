import React, { ReactNode } from "react";
import { Tag } from "antd";
import { GenericObject } from "../../../util/utils";

const COLORS: GenericObject = {
  active: "green",
  archived: "blue",
  yes: "green",
  no: "red",
};
const CUSTOM_STYLE: GenericObject = {
  active: "active-tag",
  archive: "archive-tag",
};
interface Props {
  type: string;
  color?: string;
  children: ReactNode;
}

function ColorTag({ children, color = "", type = "" }: Props) {
  return (
    <div className={`color-tag ${CUSTOM_STYLE[type]}`}>
      <Tag color={COLORS[type] || color}>{children}</Tag>
    </div>
  );
}

export default ColorTag;

import { ACTION } from "src/util/utils";
import { 
    KEYS, MODULE_KEY,
    VALIDATE_ACUITY_IMPORT,
    VALIDATE_ACUITY_IMPORT_FILE,
    VALIDATE_ACUITY_IMPORT_FILE_SUCCESS,
    VALIDATE_ACUITY_IMPORT_FILE_FAILED,
    VALIDATE_ACUITY_CLEAN_IMPORT_LAYOUT
} from "./actions";
 
export const REDUCER_KEY = KEYS[MODULE_KEY + ACTION.STATE_KEY]

export interface IValidateAcuityImportMappingState {
  loading: boolean;
  error: any;
  data: [];
  importLoading: boolean;
  importFailed: boolean;
  importValidationData: any[];
}

const defaultState: IValidateAcuityImportMappingState = {
  loading: false,
  error: null,
  data: [],
  importLoading: false,
  importFailed: false,
  importValidationData: [],
};

export const validateAcuityImportReducer = (
  state = defaultState,
  { type, payload }: any
) => {
  switch (type) {
    case VALIDATE_ACUITY_IMPORT_FILE: {
      return {
        ...state,
        importLoading: true,
      };
    }
    case VALIDATE_ACUITY_IMPORT_FILE_SUCCESS: {
      return {
        ...state,
        importLoading: false,
        importFailed: false,
        importValidationData: payload,
      };
    }
    case VALIDATE_ACUITY_IMPORT_FILE_FAILED: {
      return {
        ...state,
        importLoading: false,
        importFailed: true,
        importValidationData: payload,
      };
    }
    case VALIDATE_ACUITY_CLEAN_IMPORT_LAYOUT: {
      return {
        ...state,
        importLoading: false,
        importFailed: false,
        importValidationData: [],
      };
    }

    default:
      return state;
  }
};


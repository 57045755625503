import { Card, Divider, Form, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MODULE_KEY } from './vendor-actions'
import { fetchPicker, MODULE_KEY as FRAME_PICK_MODULE_KEY, PICKER } from '../framePick/actions';
import './../../styles/vendor.scss';
import { LeftCircleTwoTone, PlusOutlined, RightCircleTwoTone } from '@ant-design/icons';
import { fetchVendorList, getVendorPickerAssignment, saveVendorPickerAssignment } from './vendor-sagas';
import Button from '../common/Button/Button';
import { showErrorNotification, showSuccessNotification } from '../../util/notifications';
import TableFilter from '../common/TableFilter/TableFilter';
import { ACTION } from '../../util/utils';
import Breadcrumb from '../common/Breadcrumb/Breadcrumb';
import { PICKER_COLUMN, PICKER_DATA_TYPE, VENDOR_COLUMN, VENDOR_DATA_TYPE } from '../../util/columns';
import GenericTable from '../common/Table/GenericTable';
import AddPickerForm from './AddPickerForm';

function Vendor() {
  const [selectPicker, setSelectPicker] = useState<any>([])
  const [selectedVendor, setSelectVendor] = useState<any>([]);
  const [mappingList, setMappingList] = useState<any>([]);
  const [removeList, setRemoveList] = useState<any>([]);
  const [pickerList, setPickerList] = useState<PICKER_DATA_TYPE[]>([]);
  const [vendorList, setVendorList] = useState<VENDOR_DATA_TYPE[]>([]);
  const [change, setChange] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedMappingKey, setSelectedMappingKey] = useState<number | undefined>();
  const [visible, setVisible] = useState(false);

  const { picker } = useSelector((state: any) => state[`${FRAME_PICK_MODULE_KEY}${ACTION.STATE_KEY}`]);
  const dispatch = useDispatch();

  useEffect(() => {
    const unAssignedPicker = picker?.filter((a: { PickerName: string; }) => a.PickerName === "Unassigned");
    const remainingPicker = picker?.filter((a: {
      PickerId: number; PickerName: string;
    }) => a.PickerName !== "Unassigned" && a.PickerId !== 0);
    const pickerArr = remainingPicker?.sort((a: { PickerName: string; }, b: { PickerName: string; }) => {
      return a.PickerName.localeCompare(b.PickerName);
    });
    const finalPickerArr = [...unAssignedPicker, ...pickerArr];
    setPickerList(finalPickerArr)
  }, [picker])

  useEffect(() => {
    dispatch(fetchPicker({}));
  }, []);

  useEffect(() => {
    Promise.all([fetchVendorList(), getVendorPickerAssignment()]).then((res: any) => {
      const vendors: VENDOR_DATA_TYPE[] = res[0];
      const mapping = res[1];
      const unAssignedPicker = mapping?.filter((a: { PickerName: string; }) => a.PickerName === "Unassigned");
      const remainingPicker = mapping?.filter((a: {
        PickerId: number; PickerName: string;
      }) => a.PickerName !== "Unassigned" && a.PickerId !== 0);
      const pickerArr = remainingPicker?.sort((a: { PickerName: string; }, b: { PickerName: string; }) => {
        return a.PickerName.localeCompare(b.PickerName);
      });
      const finalPickerArr = [...unAssignedPicker, ...pickerArr];
      finalPickerArr?.sort((a: { PickerName: string; }, b: { PickerName: string; }) => {
        return a.PickerName.localeCompare(b.PickerName);
      });
      const filterVendors = vendors.filter((list: VENDOR_DATA_TYPE) => {
        return !finalPickerArr.find((mList: any) => list.ID === mList.VendorId)
      })
      setVendorList(filterVendors)
      const mappedVendorList = finalPickerArr.map((list: any) => {
        return [[{ PickerId: list.PickerId, PickerName: list.PickerName }, null], [{ ID: list.VendorId, VendorName: list.VendorName }, null]]
      })
      setMappingList(mappedVendorList);
    })
  }, [refresh])

  const showModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setVisible(true);
  };

  const mappingHandler = () => {
    setChange(true)
    if (selectPicker.length && selectedVendor.length) {
      setMappingList([...mappingList, [selectPicker, selectedVendor]]);
      const filteredVendorList = vendorList.filter((list: VENDOR_DATA_TYPE) => list.ID !== selectedVendor[0].ID)
      setVendorList(filteredVendorList)
      setSelectPicker([]);
      setSelectVendor([]);
    }
  }

  const removeMappingHandler = () => {
    setChange(true)
    if (selectedMappingKey !== undefined) {
      const filteredList = mappingList.filter((item: any) => {
        const picker = item[0][0]
        const vendor: VENDOR_DATA_TYPE = item[1][0];
        if (vendor.ID === selectedMappingKey) {
          const newVendorList = vendorList.slice();
          if (item[1][1] !== null) {
            newVendorList.splice(item[1][1], 0, vendor)
          } else {
            newVendorList.push(vendor);
            setRemoveList([...removeList, { PickerId: picker.PickerId, VendorID: vendor.ID, VendorName: vendor.VendorName }])
          }
          setVendorList(newVendorList);
          return false
        }
        return true;
      });
      setSelectedMappingKey(undefined);
      setMappingList(filteredList);
    }
  }

  const uploadMappingHandler = () => {
    setLoading(true)
    if (change) {
      const addMap = mappingList.map((list: any) => {
        return {
          Action: "add",
          PickerId: list[0][0].PickerId,
          VendorID: list[1][0].ID,
          VendorName: list[1][0].VendorName
        }
      })
      const removeMap = removeList.map((list: any) => {
        return {
          Action: "delete",
          ...list
        }
      })

      saveVendorPickerAssignment(addMap.concat(removeMap)).then((res: any) => {
        showSuccessNotification(res);
        setRefresh(!refresh)
        setLoading(false)
      }).catch((e) => {
        showErrorNotification(null, e);
        setLoading(false)
      })
    }
  }

  const spinning = loading;

  return (
    <div>
      <Divider />
      <Breadcrumb>
        <span >Vendor Assignment</span>
      </Breadcrumb>
      <TableFilter>
        <div className='d-flex w-100 justify-content-end form'>
          <Button
            icon={<PlusOutlined />}
            onClick={showModal}
            size="middle"
            disabled={spinning}
          >
            Add Picker
          </Button>
          <Button size="middle" disabled={!change} onClick={uploadMappingHandler}>Update</Button>
        </div>
      </TableFilter>
      <Spin spinning={loading}>
        <section className="vendor-wrapper">
          <GenericTable
            rowkey="PickerId"
            onRowSelect={(record: any, index: number) => setSelectPicker([record, index])}
            columns={PICKER_COLUMN}
            dataSource={pickerList}
            selected={selectPicker[0]}
            moduleKey={PICKER}
            xScroll={null}
            yScroll={470}
            hidePagination={true}
            style={{ height: "70vh", width: "62vh" }}
          />
          <GenericTable
            rowkey="ID"
            onRowSelect={(record: any, index: number) => setSelectVendor([record, index])}
            columns={VENDOR_COLUMN}
            dataSource={vendorList}
            selected={selectedVendor[0]}
            moduleKey={MODULE_KEY}
            xScroll={null}
            yScroll={470}
            hidePagination={true}
            style={{ height: "70vh", width: "62vh" }}
          />
          <section className='d-flex flex-column justify-content-center gap-3'>
            <RightCircleTwoTone disabled={!selectPicker.length || !selectedVendor.length} onClick={mappingHandler} style={{ fontSize: '2rem' }} />
            <LeftCircleTwoTone onClick={removeMappingHandler} style={{ fontSize: '2rem' }} />
          </section>
          <Card title="Mapping (Picker - Vendor)" className='mapping-list' style={{ height: "70vh", width: "62vh" }} >
            <div>
              {mappingList.length ? <ul >
                {mappingList.map((item: any, index: number) => {
                  const vendorId = item[1][0].ID;
                  const selected = vendorId === selectedMappingKey;
                  return <li className={`${selected && "selected-background"}`} onClick={() => setSelectedMappingKey(vendorId)}>{`${item[0][0].PickerName} - ${item[1][0].VendorName}`}</li>
                })}
              </ul> : null}
            </div>
          </Card>
        </section>
      </Spin>
      <AddPickerForm
        visible={visible}
        setVisible={setVisible}
        pickerList={pickerList}
        loading={loading}
      />
    </div>
  )
}

export default Vendor;

import { call, put } from 'redux-saga/effects';
import { default as api } from '../../util/api';
import { showErrorNotification } from '../../util/notifications'
import { FEATURE_FLAG_URL } from '../../util/constants/api.constants';
import { toQueryParam } from '../../util/utils';


export function replenismentEnabled(payload: any) {
  let url = FEATURE_FLAG_URL.DC_REPLENISHMENT_ENABLED;
  return api.get(url, {});
}

export function viewOrderEnabled(payload: any) {
  let url = FEATURE_FLAG_URL.DC_VIEW_ORDERS_ENABLED;
  return api.get(url, {});
}

export function kitsManagementEnabled(payload: any) {
  let url = FEATURE_FLAG_URL.DC_KITS_MANAGEMENT_ENABLED;
  return api.get(url, {});
}


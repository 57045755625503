import React, { useEffect, useState } from "react";
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import {
  InteractionType,
  InteractionStatus,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import styles from "./styles/App.module.scss";
import { reactApp } from "./util/constants/common.constants";
import { flow, set } from "lodash";
import Header from "./components/header/Header";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Button, Layout, Typography } from "antd";
import SIGN_IN_LOGO from "./assets/images/sign-in-logo.svg";
import {
  kitsManagementEnabled,
  replenismentEnabled,
  viewOrderEnabled,
} from "./components/navbar/sagas";
import { routes } from "./routes";
/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
  //useMsalAuthentication(InteractionType.Redirect);
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const routerBasename = reactApp().routerBasename;

  const filteredRoutes = () => {
    const modulelinks = routes.sort(
      (a: { title: string }, b: { title: any }) => {
        return a.title.localeCompare(b.title);
      }
    );
    return flow()(routes);
  };

  const [url, setUrl] = useState<any>(filteredRoutes());
  let moduleUrl = filteredRoutes();
  useEffect(() => {
    featureFlagRes();
  }, []);

  const featureFlagRes = async () => {
    try {
      replenismentEnabled({}).then((res) => {
        if (!res.Enabled) {
          moduleUrl = moduleUrl?.filter((element: any) => {
            return element.title !== "DC Replenishment";
          });
          setUrl(moduleUrl);
        }
      });
      viewOrderEnabled({}).then((res: any) => {
        if (!res.Enabled) {
          moduleUrl = moduleUrl?.filter((element: any) => {
            return element.title !== "View Orders";
          });
          setUrl(moduleUrl);
        }
      });
      kitsManagementEnabled({}).then((res: any) => {
        if (!res.Enabled) {
          moduleUrl = moduleUrl?.filter((element: any) => {
            return element.title !== "New Style Kits";
          });
          setUrl(moduleUrl);
        }
      });
    } catch (error: any) {
      console.log(error.response.data, "Feature flag functionality");
    }
  };

  return (
    <Router basename={routerBasename}>
      <div className={styles.App}>
        <div className={styles.container}>
          <Header routes={url} />
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            errorComponent={(result) => (
              <section className="login-screen">
                <section className="login-card">
                  <img src={SIGN_IN_LOGO} width={120} height={120} />
                  <div>{result.error?.message}</div>
                </section>
              </section>
            )}
            loadingComponent={() => (
              <section className="login-screen">
                <section className="login-card">
                  <img src={SIGN_IN_LOGO} width={120} height={120} />
                  <div>Validating Login</div>
                </section>
              </section>
            )}
          >
            <main className={styles.main}>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/dashboard" />
                </Route>
                {filteredRoutes().map((route: any, index: any) => {
                  if (route.children) {
                    return route.children.map((item: any, i: number) => (
                      <Route
                        key={i}
                        path={item.path}
                        children={<item.content />}
                      />
                    ));
                  } else {
                    return (
                      <Route
                        key={index}
                        path={route.path}
                        children={<route.content />}
                      />
                    );
                  }
                })}
              </Switch>
            </main>
          </MsalAuthenticationTemplate>
        </div>
      </div>
    </Router>
  );
};

export default function App() {
  return <MainContent />;
}

import { Card, DatePicker, DatePickerProps, Form, Input, Select } from "antd";
import moment from "moment";
import { Key, useEffect, useState } from "react";
import { fetchVendorListWithKits } from "src/components/vendor/vendor-sagas";
import { DATE_FORMAT } from "src/util/columns";
import { getKitListByVendor } from "./sagas";

function KitProcessForm({
  form,
  PODate,
  setPODate,
  selectedVendor,
  setSelectedVendor,
  kitData,
  selectedKit,
  setSelectedKit,
  setSelectedOffices,
}: any) {
  const { Option } = Select;
  const [vendor, setVendor] = useState<any>([]);
  const [kit, setKit] = useState<any>([]);
  const fields = form.getFieldsValue();
  useEffect(() => {
    fetchVendorListWithKits().then((res) => {
      const sortedVendor = res.sort((a: any, b: any) => {
        return a.VendorName.localeCompare(b.VendorName, "en", {
          numeric: true,
        });
      });
      setVendor(sortedVendor);
    });
  }, []);
  useEffect(() => {
    if (selectedVendor) {
      getKitListByVendor(fields?.Vendor).then((res: any) => {
        const sortedKit = res.sort((a: any, b: any) => {
          return a.KitName.localeCompare(b.KitName, "en", {
            numeric: true,
          });
        });
        setKit(sortedKit);
      });
    }
  }, [selectedVendor]);

  const handleVendor = (val: any) => {
    setSelectedVendor(
      vendor?.filter((item: any) => item.VendorId === val)[0]?.VendorName
    );
    //setSelectedOffices([]);
    setSelectedKit(undefined);
    form.resetFields(["Kit"]);
  };
  const dateRequiredValidation =
    PODate === "Invalid date"
      ? { required: true, message: "Please select date" }
      : { required: false };

  return (
    <>
      <Card className="mb-4">
        <Form
          form={form}
          initialValues={{ remember: false }}
          autoComplete="off"
          className="add-form"
          layout="vertical"
        >
          <div>
            <Form.Item
              label="PO Date"
              name="PODate"
              rules={[dateRequiredValidation]}
            >
              <DatePicker
                format={DATE_FORMAT}
                name="PODate"
                defaultValue={PODate && moment(PODate)}
                value={PODate && moment(PODate)}
                disabledDate={(d) => !d || d.isAfter(moment())}
                placeholder="Select Date"
                onChange={(value: DatePickerProps["value"]) =>
                  setPODate(moment(value).format(DATE_FORMAT))
                }
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Vendor"
              name="Vendor"
              className=""
              rules={[{ required: true, message: "Please select vendor" }]}
            >
              <Select
                showSearch={true}
                placeholder="Select Vendors"
                optionFilterProp="children"
                disabled={kitData}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(val) => handleVendor(val)}
              >
                {vendor?.map((item: any, index: any) => {
                  return (
                    <Option key={index} value={item.VendorId} label={item.VendorName}>
                      {item.VendorName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Kit Name"
              name="Kit"
              className="label-text region-field"
              rules={[{ required: true, message: "Please select kit" }]}
            >
              <Select
                showSearch={true}
                allowClear={true}
                placeholder="Select kit"
                optionFilterProp="children"
                disabled={kitData || !selectedVendor}
                style={{ width: "100%" }}
                onChange={(val) => setSelectedKit(val)}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {kit?.map((item: any, index: Key | null | undefined) => {
                  return (
                    <Option key={index} value={item?.Id} label={item?.KitName}>
                      {item?.KitName}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Description"
              name="Description"
              className="label-text input-field"
              rules={[
                {
                  pattern: new RegExp(/^[a-zA-Z0-9 ]+$/i),
                  message: "Description should be alphanumeric",
                },
                {
                  max: 100,
                  message: `Description should be less then 100 characters.`,
                },
              ]}
            >
              <Input name="Description" />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              label="Vendor Reference Number"
              name="VendorReferenceNumber"
              className="label-text input-field"
              rules={[
                {
                  pattern: new RegExp(/^[a-zA-Z0-9]+$/i),
                  message: "Vendor Reference Number should be alphanumeric",
                },
                {
                  max: 30,
                  message: `Vendor Reference Number should be less then 30 characters.`,
                },
              ]}
            >
              <Input name="VendorReferenceNumber" />
            </Form.Item>
          </div>
        </Form>
      </Card>
    </>
  );
}
export default KitProcessForm;

import { useSelector } from "react-redux";
import { MODULE_KEY } from "./actions";
import { ACTION, insertAt } from "src/util/utils";
import { KITS_PROCESSING_COLUMN } from "src/util/columns";
import Button from "src/components/common/Button/Button";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  DownSquareOutlined,
  EditOutlined,
  EyeOutlined,
  FileOutlined,
  SearchOutlined,
  UpSquareOutlined,
} from "@ant-design/icons";
import { Modal, Spin, Tooltip } from "antd";
import { printLabelReport } from "src/components/framePick/sagas";
import { useEffect, useState } from "react";
import PDFStream from "src/components/common/PDFStream/PDFStream";
import { showErrorNotification } from "src/util/notifications";

export default function filterColumns(
  deleteModal: (record: any) => void,
  editDrawer?: ((record: any) => void) | any,
  filteredData?: any,
  archiveModal?: ((record: any) => void )| any,
  setConfirmLoading?: ((arg0: boolean) => void) | any
) {
  const { data } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const [records, setRecords] = useState(data);
  useEffect(() => {
    setRecords(data);
  }, [data]);
  useEffect(() => {
    if (filteredData?.length && filteredData[0] !== "none") {
      setRecords(filteredData);
    } else {
      setRecords(data);
    }
  }, [filteredData]);
  const vendors: any | null | undefined = [];
  const kitCollection: any | null | undefined = [];
  const [stream, setStream] = useState<any>("");

  records?.map((row: any) => {
    vendors.push({ text: row.Vendor, value: row.Vendor });
    kitCollection.push({ text: row.Collection, value: row.Collection });
  });

  let vendorName = vendors.filter(
    (ele: any, ind: any) =>
      ind ===
      vendors.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );
  let kitCcollectionName = kitCollection.filter(
    (ele: any, ind: any) =>
      ind ===
      kitCollection.findIndex(
        (elem: any) =>
          elem.text === ele.text && elem.value !== "" && elem.value !== null
      )
  );

  const vendorColumns = {
    title: "Vendor Name",
    dataIndex: "Vendor",
    sorter: (a: { Vendor: string }, b: { Vendor: any }) =>
      a.Vendor?.localeCompare(b.Vendor),
    sortDirections: ["ascend", "descend"],
    filters: vendorName,
    onFilter: (value: any, record: { Vendor: string | any[] }) =>
      record.Vendor !== null && record.Vendor.indexOf(value) === 0,
    width: 100,
    align: "center",
  };

  let columns = insertAt(KITS_PROCESSING_COLUMN, 1, 0, vendorColumns);
  const [fileName, setFileName] = useState("");
  const handlePDFFile = async (record: any, type: any) => {
    setConfirmLoading(true);
    const reportName = type === "slip" ? "StockTransferReport" : "PrintTags";
    setFileName(reportName);
    let param = null;
    if (type == "slip") {
      param = { Kitid: record?.KitId };
    } else {
      param = { KitId: record?.KitId };
    }
    const params = type === "slip" ? "StockTransferReport" : "PrintTags";
    try {
      const reportPayload = {
        reportName: reportName,
        parameters: param,
      };
      await printLabelReport(reportPayload).then((result: string) => {
        if (result) {
          setStream(result);
          setConfirmLoading(false);
        }
      });
    } catch (error: any) {
      showErrorNotification(error?.response?.data?.error?.messages[0]);
      setConfirmLoading(false);
    }
  };

  const actionColumn = {
    title: "action",
    dataIndex: "operation",
    // align: "center",
    width: 80,
    render: (_: any, record: any) => {
      return (
        <div className="d-flex">
          {record?.ProcessStatus === "3" ? (
            <>
              <Button
                icon={<SearchOutlined />}
                children={undefined}
                disabled={true}
                // onClick={() => editDrawer(record)}
              ></Button>

              <Tooltip placement="top" title=" Packing Slip">
                <Button
                  style={{ marginLeft: "10px" }}
                  icon={<FileOutlined />}
                  children={undefined}
                  onClick={() => handlePDFFile(record, "slip")}
                ></Button>
              </Tooltip>
              <Tooltip placement="top" title="Price Bar Codes">
                <Button
                  style={{ marginLeft: "10px" }}
                  icon={<FileOutlined />}
                  children={undefined}
                  onClick={() => handlePDFFile(record, "barcode")}
                ></Button>
              </Tooltip>
            </>
          ) : (
            <>
              <Button
                icon={<EditOutlined />}
                children={undefined}
                onClick={() => editDrawer(record)}
              ></Button>
              <Button
                style={{ marginLeft: "20px" }}
                disabled={true}
                icon={<DeleteOutlined />}
                onClick={() => deleteModal(record)}
                children={undefined}
                danger={true}
              ></Button>
            </>
          )}

          {record?.ProcessStatus === "3" && record?.IsArchived && (
            <Tooltip placement="top" title="Unarchive">
              <Button
                style={{ marginLeft: "20px" }}
                icon={<UpSquareOutlined />}
                children={undefined}
                onClick={() => archiveModal(record)}
                danger={true}
              ></Button>
            </Tooltip>
          )}
          {record?.ProcessStatus === "3" && !record?.IsArchived && (
            <Tooltip placement="top" title="Archive">
              <Button
                style={{ marginLeft: "20px" }}
                icon={<DownSquareOutlined />}
                children={undefined}
                onClick={() => archiveModal(record)}
                danger={true}
              ></Button>
            </Tooltip>
          )}

          {stream ? (
            <Modal
              visible={true}
              onOk={() => setStream("")}
              onCancel={() => setStream("")}
              footer={null}
              width={1000}
              destroyOnClose
            >
              <PDFStream
                onPrint={() => setStream("")}
                stream={stream}
                fileName={fileName}
              />
            </Modal>
          ) : null}
        </div>
      );
    },
  };
  columns = insertAt(columns, 7, 0, actionColumn);

  return { columns };
}

import { BASE_URL } from './constants/api.constants';
import axios from 'axios';

class Api {
  generateFullUrl(url: string) {
    const urlObj = new URL(`${BASE_URL}${url}`);
    return urlObj.toString();
  }

  get = async (url: string, params?: any) => {
    const response = await axios.get(this.generateFullUrl(url),{ params });

    return response.data;
  };

  post = async (url: string, payload: any, params?: any) => {
    const response = await axios.post(this.generateFullUrl(url), payload, { params });

    return response.data;
  };

  put = async (url: string, payload: any, params?: any) => {
    const response = await axios.put(this.generateFullUrl(url), payload, { params });

    return response.data;
  };


  delete = async (url: string, payload?: any) => {
    const response = await axios.delete(this.generateFullUrl(url), { data: payload });

    return response.data;
  };

  patch = async (url: string, payload?: any) => {
    const response = await axios.patch(this.generateFullUrl(url), payload);

    return response.data;
  };
}

export default new Api();

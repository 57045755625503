import { Card, Descriptions, Divider, Form, Modal, Spin } from "antd";
import { KIT_FRAME_MODULE_KEY } from "./actions";
import GenericTable from "src/components/common/Table/GenericTable";
import TableFilter from "src/components/common/TableFilter/TableFilter";
import Breadcrumb from "src/components/common/Breadcrumb/Breadcrumb";
import Button from "src/components/common/Button/Button";
import { useState } from "react";
import filterColumns from "./filterColumns";

import { useHistory, useLocation } from "react-router-dom";

import { getKitData } from "./sagas";
import moment from "moment";
import { DATE_FORMAT } from "src/util/columns";

function KitDetailPage() {
  const history = useHistory();
  const location = useLocation();
  const kitData: any = location.state;
  let filterValues = filterColumns();
  let columns = filterValues?.frameColumns?.filter(
    (item: any) => item.title !== "action"
  );

  const handleCancel = () => {
    history.push("/kitMaintenance");
  };

  return (
    <>
      <Spin spinning={false}>
        <Divider />
        <Breadcrumb>
          <span onClick={() => history.push("/kitMaintenance")}>
            Kit Maintenance
          </span>
          <span>Kit Maintenance Details Pages</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end flex"></div>
          <div className="d-flex justify-content-end">
            <Button
              size="middle"
              onClick={handleCancel}
              ant-click-animating-without-extra-node="true"
            >
              Close
            </Button>
          </div>
        </TableFilter>
        <Card>
          <Descriptions column={3} className="mb-5">
            <Descriptions.Item label="Kit ID">
              {kitData.KitId}
            </Descriptions.Item>
            <Descriptions.Item label="Kit Name">
              {kitData.KitName}
            </Descriptions.Item>
            <Descriptions.Item label="Vendor">
              {kitData.Vendor}
            </Descriptions.Item>
            <Descriptions.Item label="Date">
              {kitData.ModifiedDate
                ? moment(kitData.ModifiedDate).format(DATE_FORMAT)
                : "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Planned Ship Date">
              {kitData.PlannedShipDate
                ? moment(kitData.PlannedShipDate).format(DATE_FORMAT)
                : "N/A"}
            </Descriptions.Item>
            <Descriptions.Item label="Status">
              {kitData?.StatusText}
            </Descriptions.Item>
            <Descriptions.Item label="Kit Processed Status">
              {kitData.ProcessStatus &&
              (kitData.ProcessStatus === 3 || kitData.ProcessStatus === 4)
                ? "Yes"
                : "No"}
            </Descriptions.Item>
            <Descriptions.Item label="Collection">
              {kitData.Collection}
            </Descriptions.Item>
          </Descriptions>
          <GenericTable
            rowkey="UPC"
            columns={columns}
            moduleKey={KIT_FRAME_MODULE_KEY}
            dataSource={kitData?.Frames || []}
            xScroll={1000}
            yScroll={"calc(90vh - 17.5em)"}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "50", "100"],
            }}
          />
        </Card>
      </Spin>
    </>
  );
}
export default KitDetailPage;

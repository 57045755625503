import { notification } from "antd"

export const NOTIFICATION_MESSAGE = {
    API_FAIL: "{{}} request was not successful. Please try again",
    API_SUCCESS: "{{}} updated successfully.",
    API_CREATE : "{{}} request created successfully."
}

export class Notification {
    tag = ''
    constructor(tag) {
        this.tag = tag
    }

    success(tag = this.tag, message = NOTIFICATION_MESSAGE.API_SUCCESS) {
        const notificationMessage = message.replace("{{}}", tag);
        notification.success({
            message: 'Success',
            description: notificationMessage
        })
    }
    create(tag = this.tag, message = NOTIFICATION_MESSAGE.API_CREATE) {
        const notificationMessage = message.replace("{{}}", tag);
        notification.success({
            message: 'Success',
            description: notificationMessage
        })
    }

    error(tag = this.tag, message = NOTIFICATION_MESSAGE.API_FAIL) {
        const notificationMessage = message.replace("{{}}", tag);
        notification.error({
            message: 'Error',
            description: notificationMessage
        })
    }
}
import { call, put } from "redux-saga/effects";
import {
  fetchAssortmentSkuSuccess,
  fetchFrameArrangerQtyFailed,
  fetchFrameArrangerQtySuccess,
  importFileFailed,
  importFileSuccess,
  assortmentImportFileSuccess,
  assortmentImportFileFailed
} from "./actions";
import { showErrorNotification } from "src/util/notifications";
import api from "src/util/api";
import { FRAME_ARRANGER_QTY_URL } from "src/util/constants/api.constants";

export function* fetchFrameArrangerQty({ payload }: any): Generator {
  try {
    let url = FRAME_ARRANGER_QTY_URL.FRAME_ARRANGER_QTY_LIST;
    const data: any = yield call(api.post, url, payload);
    yield put(fetchFrameArrangerQtySuccess(data));
  } catch (e: any) {
    const errorMessage =
      typeof e.response === "string"
        ? e?.response
        : e?.response?.data?.error?.messages[0];
    yield put(fetchFrameArrangerQtyFailed(e.response.data));
    showErrorNotification(errorMessage);
  }
}

export function* importFileAssortment({
  payload,
  actionType,
}: any): Generator {
  try {
    let url = `${FRAME_ARRANGER_QTY_URL.ASSORTMENT_IMPORT}`;
    const formdata = new FormData();
    formdata.append("file", payload.file);
    const data: any = yield call(api.post, url, formdata);
    yield put(assortmentImportFileSuccess(data));
  } catch (e: any) {
    yield put(assortmentImportFileFailed(e.response?.data?.error?.messages[0]));
  }
}

export function saveFrameArrangerQty(payload: any) {
  let url = `${FRAME_ARRANGER_QTY_URL.FRAME_ARRANGER_QTY_SAVE}`;
  return api.post(url, payload);
}

export function updateFrameArrangerQty(payload: any) {
  let url = `${FRAME_ARRANGER_QTY_URL.FRAME_ARRANGER_QTY_SAVE}`;
  return api.put(url, payload);
}

export function getDatabaseCollection() {
  let url = `${FRAME_ARRANGER_QTY_URL.DATABASE_COLLECTION_LIST}`;
  return api.get(url);
}
export function getFrameType(payload: any) {
  let url = FRAME_ARRANGER_QTY_URL.FRAME_TYPE_LIST;
  return api.get(url, payload);
}

export function getFADetail(payload: any) {
  let url = FRAME_ARRANGER_QTY_URL.EDIT_FA_QTY + payload;
  return api.get(url);
}

export function* fetchAssortmentSku({ payload }: any): Generator {
  try {
    let url = FRAME_ARRANGER_QTY_URL.ASSORTMENT_SKU;
    const data: any = yield call(api.get, url, payload);
    yield put(fetchAssortmentSkuSuccess(data));
  } catch (e: any) {
    showErrorNotification(null, e);
  }
}
export function fetchSKUDetails(payload: any) {
  let url = FRAME_ARRANGER_QTY_URL.ASSORTMENT_SKU + payload;
  return api.get(url);
}

export function validateAssortmentSku(payload: any) {
  let url = FRAME_ARRANGER_QTY_URL.VALIDATE_SKU;
  return api.post(url, payload);
}

export function* validateSKUFile({ payload, actionType }: any): Generator {
  try {
    let url =
      FRAME_ARRANGER_QTY_URL.IMPORT_SKU_VALIDATION +
      `${payload?.params?.VendorName}/${payload?.params?.CollectionName}`;
    const formdata = new FormData();
    formdata.append("file", payload.file);
    const data: any = yield call(api.post, url, formdata);
    yield put(importFileSuccess(data));
  } catch (e: any) {
    const errorMessage =
      typeof e.response === "string"
        ? e?.response
        : e?.response?.data?.error?.messages[0];
    yield put(importFileFailed(e.response.data));
    showErrorNotification(errorMessage, null);
  }
}

export function fetchAssortmentCount(payload: any) {
  let url = FRAME_ARRANGER_QTY_URL.ASSORTMENT_COUNT + payload;
  return api.get(url);
}

export function deleteAssortment(payload: any) {
  let url = FRAME_ARRANGER_QTY_URL.ASSORTMENT_DELETE;
  return api.delete(url, payload);
}

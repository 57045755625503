import { call, put, select } from 'redux-saga/effects';
import { fetchVendors, fetchVendorsSuccess, fetchVendorsFailed } from './vendor-actions';
import { get, omit } from 'lodash';
import { default as api } from '../../util/api';
import { showErrorNotification } from '../../util/notifications'
import { KITS_MAINTENANCE_URL, VENDOR_URL } from '../../util/constants/api.constants';


export function fetchVendorList() {
  let url = `${VENDOR_URL.VENDOR_LIST}`;
  return api.get(url, {});
}

export function fetchVendorListWithKits() { 
  let url = `${KITS_MAINTENANCE_URL.VENDOR_LIST_WITH_KITS}`;
  return api.get(url, {});
}

export function* fetchVendor({ payload }: any): Generator {
  try {
    let url = VENDOR_URL.VENDOR_LIST;
    const { page = 0 } = payload;
    const data: any = yield call(api.get, url);
    yield put(fetchVendorsSuccess(data));
  } catch (e: any) {
    showErrorNotification(null, e);
  }
}

export function getVendorPickerAssignment() {
  let url = `${VENDOR_URL.VENDOR_PICKER_ASSIGNMENT}`;
  return api.get(url, {});
}

export function saveVendorPickerAssignment(payload: any) {
  let url = `${VENDOR_URL.VENDOR_PICKER_ASSIGNMENT}`;
  return api.post(url, payload);
}

export function addUpdatePicker(payload: any) {
  let url = `${VENDOR_URL.CREATE_UPDATE_PICKER}`;
  return api.post(url, payload);
}
import { useSelector } from "react-redux";
import { MODULE_KEY } from "./openPull-actions";
import { ACTION, insertAt } from "../../util/utils";
import { DC_REPLENISHMENT_COLUMN, OPEN_PULL_COLUMN } from "../../util/columns";

export default function filterColumns() {
  const { data } = useSelector(
    (state: any) => state[`${MODULE_KEY}${ACTION.STATE_KEY}`]
  );
  const collectionName: any | null | undefined = [];
  const vendors: any | null | undefined = [];
  const pickers: any | null | undefined = [];
  const itemStatus: any | null | undefined = [];
  const lab: any | null | undefined = [];
  const labStatus: any | null | undefined = [];
  const locationStatus: any | null | undefined = [];
  const colors: any | null | undefined = [];
  data?.map((row: any) => {
    collectionName.push({ text: row.Collection, value: row.Collection });
    vendors.push({ text: row.Vendor, value: row.Vendor });
    pickers.push({ text: row.NickName, value: row.NickName });
    itemStatus.push({ text: row.ItemStatus, value: row.ItemStatus });
    lab.push({ text: row.LabName, value: row.LabName });
    labStatus.push({ text: row.LabStatus, value: row.LabStatus });
    locationStatus.push({
      text: row.LocationStatus,
      value: row.LocationStatus,
    });
    colors.push({ text: row.ColorRow, value: row.ColorRow });
  });
  let frameCollectionName = collectionName.filter(
    (ele: any, ind: any) =>
      ind === collectionName.findIndex((elem: any) => elem.text === ele.text)
  );
  let vendorName = vendors.filter(
    (ele: any, ind: any) =>
      ind === vendors.findIndex((elem: any) => elem.text === ele.text)
  );
  let pickerName = pickers.filter(
    (ele: any, ind: any) =>
      ind ===
      pickers.findIndex(
        (elem: any) => elem.text === ele.text && elem.text !== ""
      )
  );
  let itemStatusFilter = itemStatus.filter(
    (ele: any, ind: any) =>
      ind ===
      itemStatus.findIndex(
        (elem: any) => elem.text === ele.text && elem.text !== ""
      )
  );
  let labName = lab.filter(
    (ele: any, ind: any) =>
      ind ===
      lab.findIndex((elem: any) => elem.text === ele.text && elem.text !== "")
  );
  let labStatusFilter = labStatus.filter(
    (ele: any, ind: any) =>
      ind ===
      labStatus.findIndex(
        (elem: any) => elem.text === ele.text && elem.text !== ""
      )
  );
  let locationStatusFilter = locationStatus.filter(
    (ele: any, ind: any) =>
      ind ===
      locationStatus.findIndex(
        (elem: any) => elem.text === ele.text && elem.text !== ""
      )
  );
  let colorsName = colors.filter(
    (ele: any, ind: any) =>
      ind ===
      colors.findIndex(
        (elem: any) => elem.text === ele.text && elem.text !== ""
      )
  );

  const collectionColumns = {
    title: "Collection",
    dataIndex: "Collection",
    sorter: (a: { Collection: string }, b: { Collection: any }) =>
      a.Collection?.localeCompare(b.Collection),
    sortDirections: ["ascend", "descend"],
    filters: frameCollectionName,
    onFilter: (value: any, record: { Collection: string | any[] }) =>
      record.Collection.indexOf(value) === 0,
    width: 150,
  };
  const vendorColumns = {
    title: "Vendor Name",
    dataIndex: "Vendor",
    sorter: (a: { Vendor: string }, b: { Vendor: any }) =>
      a.Vendor?.localeCompare(b.Vendor),
    sortDirections: ["ascend"],
    filters: vendorName,
    onFilter: (value: any, record: { Vendor: string | any[] }) =>
      record.Vendor.indexOf(value) === 0,
    width: 150,
  };
  const pickerColumns = {
    title: "Picker Name",
    dataIndex: "NickName",
    sorter: (a: { NickName: string }, b: { NickName: any }) =>
      a.NickName?.localeCompare(b.NickName),
    sortDirections: ["ascend", "descend"],
    filters: pickerName,
    onFilter: (value: any, record: { NickName: string | any[] }) =>
      record.NickName.indexOf(value) === 0,
    width: 150,
  };
  const itemStatusColumns = {
    title: "Item Status",
    dataIndex: "ItemStatus",
    sorter: (a: { ItemStatus: string }, b: { ItemStatus: any }) =>
      a.ItemStatus?.localeCompare(b.ItemStatus),
    sortDirections: ["ascend", "descend"],
    filters: itemStatusFilter,
    onFilter: (value: any, record: { ItemStatus: string | any[] }) =>
      record.ItemStatus.indexOf(value) === 0,
    width: 150,
  };
  const labColumns = {
    title: "Lab Name",
    dataIndex: "LabName",
    sorter: (a: { LabName: string; }, b: { LabName: any; }) => a.LabName?.localeCompare(b.LabName),
    sortDirections: ["ascend", "descend"],
    filters: labName,
    onFilter: (value: any, record: { NickName: string | any[] }) =>
      record.NickName.indexOf(value) === 0,
    width: 150,
  };
  const labStatusColumns = {
    title: "Lab Status",
    dataIndex: "LabStatus",
    sorter: (a: { LabStatus: string }, b: { LabStatus: any }) =>
      a.LabStatus?.localeCompare(b.LabStatus),
    sortDirections: ["ascend", "descend"],
    filters: labStatusFilter,
    onFilter: (value: any, record: { LabStatus: string | any[] }) =>
      record.LabStatus.indexOf(value) === 0,
    width: 150,
  };
  const locationStatusColumns = {
    title: "Location Status",
    dataIndex: "LocationStatus",
    sorter: (a: { LocationStatus: string }, b: { LocationStatus: any }) =>
      a.LocationStatus?.localeCompare(b.LocationStatus),
    sortDirections: ["ascend", "descend"],
    filters: locationStatusFilter,
    onFilter: (value: any, record: { LocationStatus: string | any[] }) =>
      record.LocationStatus.indexOf(value) === 0,
    width: 150,
  };
  const colorColumns = {
    title: "Color",
    dataIndex: "ColorRow",
    sorter: (a: { ColorRow: string }, b: { ColorRow: any }) =>
      a.ColorRow?.localeCompare(b.ColorRow),
    sortDirections: ["ascend", "descend"],
    filters: colorsName,
    onFilter: (value: any, record: { ColorRow: string | any[] }) =>
      record.ColorRow.indexOf(value) === 0,
    width: 150,
  };
  let columns = insertAt(OPEN_PULL_COLUMN, 1, 0, pickerColumns);
  columns = insertAt(columns, 3, 0, collectionColumns);
  columns = insertAt(columns, 5, 0, itemStatusColumns);
  columns = insertAt(columns, 8, 0, vendorColumns);
  columns = insertAt(columns, 11, 0, itemStatusColumns);
  columns = insertAt(columns, 12, 0, locationStatusColumns);
  columns = insertAt(columns, 15, 0, colorColumns);

  return { columns};
}

import { Drawer, Space, Table } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import Button from "src/components/common/Button/Button";
import { PP_COLUMNS } from "src/util/columns";
const Draggable = require("array-move");

import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortEnd,
} from "react-sortable-hoc";


const SortableItem = SortableElement(
  (props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />
);
const SortableBody = SortableContainer(
  (props: React.HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />
);
function ColumnDrawer({
  visible,
  setVisibleStatus,
  filterValues,
  setTableColumns,
  cookies,
  setCookie,
  removeCookie,
}: any) {
  const [dataSource, setDataSource] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<any>();
  const handleColumnsOrder = useCallback(
    (cookiesData: any[], defaultColumns: any[]) => {
      const filterRecords = defaultColumns.filter(
        (col: { dataIndex: any }) =>
          !cookiesData.some(
            (cookieCol: { dataIndex: any }) =>
              cookieCol.dataIndex === col.dataIndex
          )
      );
      return [...cookiesData, ...filterRecords].filter(
        (item) => item.title !== "action"
      );
    },
    []
  );
  const defaultColumns = useMemo(() => {
    return cookies["pp-columns"]?.length
      ? handleColumnsOrder(
          cookies["pp-columns"],
          filterValues?.drawerColumns
        )
      : filterValues?.drawerColumns;
  }, [cookies, filterValues]);

  useEffect(() => {
    if (filterValues?.data?.length) {
      setDataSource(defaultColumns);
      setTableColumns(
        cookies["pp-columns"]?.length ? defaultColumns : filterValues?.columns
      );
      setSelectedRow(
        cookies["pp-columns"]?.length
          ? cookies["pp-columns"]
          : filterValues?.columns
      );
    }
  }, [filterValues?.data?.length]);
  const actionColumn = useMemo(
    () =>
      filterValues?.columns?.find(
        (item: { title: string }) => item.title === "action"
      ),
    [filterValues]
  );
  const handleClose = () => {
    setVisibleStatus(false);
    setSelectedRow(filterValues?.columns);
  };
  const handleOk = () => {
    const orderedColumns = dataSource?.filter((item: { dataIndex: any }) =>
      selectedRow.some(
        (row: { dataIndex: any }) => row.dataIndex === item.dataIndex
      )
    );
    const actionAppend = [...orderedColumns, actionColumn].filter(Boolean);
    removeCookie("pp-columns", []);
    setCookie("pp-columns", actionAppend);
    setTableColumns(actionAppend);
    showSuccessNotification("Columns update successfully");
    setVisibleStatus(false);
  };
  const handleRestoreColumns = () => {
    removeCookie("pp-columns", []);
    setCookie("pp-columns", filterValues?.columns);
    setSelectedRow(filterValues?.columns);
    setTableColumns(filterValues?.columns);
    setVisibleStatus(false);
  };
  const drawerTitle = "Manage Columns";
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRow(selectedRows);
    },
    clearSelection: () => {
      setSelectedRow([]);
    },
    selectedRowKeys: selectedRow?.map((row: any) => row?.dataIndex),
    //hideSelectAll: true,
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const newData = Draggable.arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter((el: any) => !!el);
      setDataSource(newData);
    }
  };

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  const DraggableBodyRow: React.FC<any> = ({
    className,
    style,
    ...restProps
  }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource?.findIndex(
      (x: { dataIndex: any }) => x.dataIndex === restProps["data-row-key"]
    );
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <>
      <Drawer
        title={drawerTitle}
        placement="right"
        visible={visible}
        onClose={handleClose}
        width={450}
        closable={false}
        className="dc-drawer-panel"
        forceRender={true}
        extra={
          <Space>
            <Button onClick={handleRestoreColumns}>Restore Default</Button>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleOk}>Save</Button>
          </Space>
        }
      >
        <Table
          pagination={false}
          dataSource={dataSource}
          columns={PP_COLUMNS}
          rowSelection={rowSelection}
          rowKey="dataIndex"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      </Drawer>
    </>
  );
}

export default ColumnDrawer;

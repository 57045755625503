import { ACTION, GenericObject, actionKeyGenerate } from "src/util/utils";

export const MODULE_KEY = "KITS_MAINTENANCE";
export const KIT_FRAME_MODULE_KEY = "KIT_FRAME";

export const KEYS: GenericObject = actionKeyGenerate(MODULE_KEY);
export const KIT_FRAME_KEYS: GenericObject =
  actionKeyGenerate(KIT_FRAME_MODULE_KEY);

export const KITS_MAINTENANCE_FETCH = KEYS[MODULE_KEY + ACTION.FETCH];
export const KIT_FRAME_FETCH =
  KIT_FRAME_KEYS[KIT_FRAME_MODULE_KEY + ACTION.FETCH];

export const fetchKitsMaintenance = (payload: any) => ({
  type: KITS_MAINTENANCE_FETCH,
  payload,
});

export const fetchKitsMaintenanceSuccess = (payload: any) => ({
  type: KEYS[MODULE_KEY + ACTION.SUCCESS],
  payload,
});

export const fetchKitsMaintenanceFailed = (payload: any) => ({
  type: KEYS[MODULE_KEY + ACTION.FAILED],
  payload,
});

export const fetchKitFrame = (payload: any) => ({
  type: KIT_FRAME_FETCH,
  payload,
});

export const fetchKitFrameSuccess = (payload: any) => ({
  type:KIT_FRAME_KEYS[KIT_FRAME_MODULE_KEY + ACTION.SUCCESS],
  payload,
});

export const fetchKitFrameFailed = (payload: any) => ({
  type:KIT_FRAME_KEYS[KIT_FRAME_MODULE_KEY + ACTION.FAILED],
  payload,
});

import { ACTION } from "../../util/utils";
import { KEYS, MODULE_KEY, PICKER, PICKER_KEYS } from "./actions";

export const REDUCER_KEY = KEYS[MODULE_KEY + ACTION.STATE_KEY]

export interface IPOpenPullState {
  loading: boolean;
  error: any;
  data: []
  picker: []
}

const defaultState: IPOpenPullState = {
  loading: false,
  error: null,
  data: [],
  picker:[]
};

export const framePickReducer = (
  state = defaultState,
  { type, payload }: any
) => {
  switch (type) {
    case KEYS[MODULE_KEY + ACTION.FETCH]:
      return {
        ...state,
        loading: true,
      };
    case KEYS[MODULE_KEY + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        data: [...payload],
      };
    case KEYS[MODULE_KEY + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case PICKER_KEYS[PICKER + ACTION.FETCH]:
      return {
        ...state,
        loading: true,
      };
    case PICKER_KEYS[PICKER + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        picker: [...payload],
      };
    case PICKER_KEYS[PICKER + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

import { actionKeyGenerate, ACTION, GenericObject } from "../../util/utils";

export const MODULE_KEY = "ACCESSORY_REPLENISHMENT";

export const KEYS: GenericObject = actionKeyGenerate(MODULE_KEY);

export const ACCESSORY_REPLENISHMENT_FETCH = KEYS[MODULE_KEY + ACTION.FETCH]

export const fetchAccessoryReplenishment = (payload: any) => ({
    type: ACCESSORY_REPLENISHMENT_FETCH,
    payload,
  });
  
  export const fetchAccessoryReplenishmentSuccess = (payload: any) => ({
    type: KEYS[MODULE_KEY + ACTION.SUCCESS],
    payload,
  });
  
  export const fetchAccessoryReplenishmentFailed = (payload: any) => ({
    type: KEYS[MODULE_KEY + ACTION.FAILED],
    payload,
  });

  
import { actionKeyGenerate, ACTION, GenericObject } from "../../util/utils";

export const MODULE_KEY = "PRINT_TAGS";
export const OFFICE_LOCATION = "OFFICE_LOCATION"

export const KEYS: GenericObject = actionKeyGenerate(MODULE_KEY);
export const OFFICE_LOCATION_KEYS: GenericObject = actionKeyGenerate(OFFICE_LOCATION);

export const FETCH_PRINT_TAGS = KEYS[MODULE_KEY + ACTION.FETCH]
export const FETCH_OFFICE_LOCATION = OFFICE_LOCATION_KEYS[OFFICE_LOCATION + ACTION.FETCH]


export const fetchOfficeLocation = (payload: any) => ({
    type: FETCH_OFFICE_LOCATION,
    payload,
  });
  
  export const fetchOfficeLocationSuccess = (payload: any) => ({
    type: OFFICE_LOCATION_KEYS[OFFICE_LOCATION + ACTION.SUCCESS],
    payload,
  });
  
  export const fetchOfficeLocationFailed = (payload: any) => ({
    type: OFFICE_LOCATION_KEYS[OFFICE_LOCATION + ACTION.FAILED],
    payload,
  });

export const fetchPrintTags = (payload: any) => ({
    type: FETCH_PRINT_TAGS,
    payload,
  });
  
  export const fetchPrintTagsSuccess = (payload: any) => ({
    type: KEYS[MODULE_KEY + ACTION.SUCCESS],
    payload,
  });
  
  export const fetchPrintTagsFailed = (payload: any) => ({
    type: KEYS[MODULE_KEY + ACTION.FAILED],
    payload,
  });
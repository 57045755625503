import {
  Card,
  Form,
  Input,
  Drawer,
  Space,
  Spin,
  Select,
  Radio,
  Checkbox,
  DatePicker,
  DatePickerProps,
  Modal,
} from "antd";
import { useEffect, useState } from "react";
import {
  showErrorNotification,
  showSuccessNotification,
} from "../../util/notifications";
import Button from "../common/Button/Button";
import {
  getAllOffices,
  getProduct,
  getProductCategory,
  saveAccessoryReplenishmentItems,
} from "./sagas";
import { replenishmentType } from "src/util/utils";
import moment from "moment";
import { DATE_TIME_FORMAT_REPLENISHMENT } from "src/util/columns";
import "./../../styles/vendor.scss";
import TextArea from "antd/lib/input/TextArea";
import { validateOfficeList } from "../kits/KitProcessing/sagas";

function AddPreferences({
  visible,
  setVisibleStatus,
  setSelectedRow,
  selectedRow,
  onLoad,
}: any) {
  const { Option } = Select;
  const [rTypeValue, setRTypeValue] = useState("Inventory Based");
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showProduct, setShowProduct] = useState(true);
  const [categories, setCategories] = useState([]);
  const [officeData, setOfficeData] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [regionValue, setRegionValue] = useState([]);
  const [officeModal, setOfficeModal] = useState(false);
  const [officeList, setOfficeList] = useState<any>([]);
  const [validatedOffices, setValidatedOffices] = useState<any>([]);
  const [officeSelectMode, setOfficeSelectMode] = useState("");
  let memoizeDate = moment().format(DATE_TIME_FORMAT_REPLENISHMENT);
  const [lastReplenishmentDate, setLastReplenishmentDate] =
    useState<any>(memoizeDate);

  useEffect(() => {
    if (visible) {
      const location: any = [];
      const region: any = [];
      Promise.all([getAllOffices({}), getProductCategory({})]).then((res) => {
        res[0]?.map((item: any) => {
          location.push(item?.LocationNumber);
          if (!region.includes(item.Region)) {
            region.push(item.Region);
          }
        });
        setLocationData(location);
        setRegionData(region);
        setOfficeData(res[0]);
        const categories = res[1]?.sort((a: any, b: any) => {
          return a.localeCompare(b, "sv");
        });
        setCategories(categories);
      });
      form.setFieldValue("IsConsiderBackorder", true);
    }
  }, [visible]);

  const handleClose = () => {
    setVisibleStatus(false);
    form.resetFields();
    form.setFieldValue("IsConsiderBackorder", true);
    setSelectedRow([]);
    setRegionData([]);
    setDistrictData([]);
    setRTypeValue("Inventory Based");
    setOfficeList([]);
    setValidatedOffices([]);
    setLastReplenishmentDate(memoizeDate);
    setShowProduct(true);
  };
  const handleOk = async () => {
    const row = (await form.validateFields()) as any;
    setConfirmLoading(true);
    try {
      const fields = form.getFieldsValue();
      const data: any = [];
      const product: any = [];
      let officeData: any = [];
      if (fields.Product[0] === "All") {
        productData.map((ele: any) => {
          product.push(ele);
        });
      } else {
        fields.Product?.map((item: any) => {
          data.push(item.split(" ")[0].replace(/[{()}]/g, ""));
        });
        productData.filter(
          (ele: any, ind: any) =>
            ind ===
            data.findIndex((elem: any) => {
              if (elem === ele.UPCCode) {
                product.push(ele);
              }
            })
        );
      }
      if (fields?.Offices?.[0] === "All") {
        officeData = locationData;
      } else {
        officeData = !validatedOffices?.length
          ? fields.Offices
          : validatedOffices?.map((item: any) => item.LocationNumber);
      }

      let submitData: any = {
        SKU: product,
        OfficeNumber: officeData,
        ReplenishThreshold: fields.TriggerQuntity,
        OrderQuantity: fields.OrderQuantity,
        ReplenishmentType: rTypeValue,
        IsStockedAtDC: fields.IsStockedAtDC,
        LastReplenishmentDate: lastReplenishmentDate,
        Active: true,
        IsConsiderBackorder: fields.IsConsiderBackorder,
      };
      if (rTypeValue === "Sales Based") {
        submitData.LastReplenishmentDate = lastReplenishmentDate;
      } else {
        submitData.LastReplenishmentDate = "";
      }
      saveAccessoryReplenishmentItems(submitData)
        .then((result: any) => {
          handleClose();
          onLoad();
          setConfirmLoading(false);
          showSuccessNotification(result);
        })
        .catch((error: any) => {
          showErrorNotification(error?.response?.data?.error?.messages[0]);
          setConfirmLoading(false);
        });
    } catch (error: any) {
      showErrorNotification(error);
      setConfirmLoading(false);
    }
  };
  const onSearch = (value: string) => {
    // console.log("search:", value);
  };
  const handleAllData = async (key: any, value: any) => {
    switch (key) {
      case "office":
        if (value.length == 0) {
          setOfficeSelectMode("");
          setDistrictData([]);
          form.resetFields(["Offices", "District", "Region"]);
        }
        break;
      case "category":
        setShowProduct(false);
        setProductData([]);
        await getProduct({ ItemGroup: value }).then((res: any) => {
          const product = res?.sort((a: any, b: any) => {
            return a.ItemName.localeCompare(b.ItemName, "en", {
              numeric: true,
            });
          });
          setProductData(product);
        });
        break;
      case "region":
        const districtLocation: any = [];
        const district: any = [];
        form.resetFields(["Offices", "District"]);
        setOfficeSelectMode("region");
        if (value.length > 0) {
          officeData?.map((item: any) => {
            value?.map((ele: any) => {
              if (ele === item.Region) {
                if (!district.includes(item.District)) {
                  district.push(item.District);
                }
                districtLocation.push(item.LocationNumber);
              }
            });
          });
          setRegionValue(value);
          const districtData = district?.sort((a: any, b: any) => {
            return a.localeCompare(b, "en", { numeric: true });
          });
          setDistrictData(districtData);
          setLocationData(districtLocation);
        } else {
          form.setFieldsValue({ District: [] });
          setDistrictData([]);
          const loc: any = officeData?.map((item: any) => item.LocationNumber);
          setLocationData(loc);
          setOfficeSelectMode("");
        }
        break;
      case "district":
        const location: any = [];
        form.resetFields(["Offices"]);
        if (value.length > 0) {
          officeData?.map((item: any) => {
            value?.map((ele: any) => {
              if (ele === item.District) {
                if (!location.includes(item.LocationNumber)) {
                  location.push(item.LocationNumber);
                }
              }
            });
          });
          setLocationData(location);
        } else {
          officeData?.map((item: any) => {
            regionValue?.map((ele: any) => {
              if (ele === item.Region) {
                location.push(item.LocationNumber);
              }
            });
          });
          setLocationData(location);
        }
        break;
      default:
      // code block
    }
  };
  const handleOfficeList = () => {
    if (officeList?.length > 0) {
      validateOfficeList(officeList?.trim().split("\n"))
        .then((res: any) => {
          if (res?.length > 0) {
            setOfficeList([]);
            setValidatedOffices(res);
            const seletedOffices = res.map((item: any) => item.LocationNumber);
            form.setFieldValue("Offices", seletedOffices);
            setOfficeModal(false);
            showSuccessNotification("Offices added successfully");
          }
          setOfficeSelectMode("QuickEntry");
        })
        .catch((error: any) => {
          showErrorNotification(error.response.data?.error?.messages[0]);
        });
    } else {
      showErrorNotification("Please enter atleast one office");
    }
  };
  return (
    <>
      <Drawer
        title="Add Preferences"
        placement="right"
        visible={visible}
        onClose={handleClose}
        width={600}
        closable={false}
        className="dc-drawer-panel"
        forceRender={true}
        extra={
          <Space>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleOk}>Submit</Button>
          </Space>
        }
      >
        <Spin spinning={confirmLoading}>
          <div className="">
            <Form
              form={form}
              initialValues={{ remember: false }}
              autoComplete="off"
              className="add-picker-form"
              layout="vertical"
              style={{
                backgroundColor: "#f3f3f3",
                borderRadius: "16px",
                marginRight: "24px",
                boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",
              }}
            >
              {" "}
              <Card>
                <div>
                  <Form.Item
                    label="Product Category"
                    name="Product Category"
                    className="label-text region-field"
                  >
                    <Select
                      placeholder="Select Product Category"
                      optionFilterProp="children"
                      onChange={(value) => handleAllData("category", value)}
                      style={{ width: "100%" }}
                      filterOption={(input, option) =>
                        (option!.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {categories?.map((category, index) => {
                        return (
                          <Option key={index} value={category}>
                            {category}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="Product"
                    name="Product"
                    className="label-text region-field"
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "Please select atleast one product",
                      },
                    ]}
                  >
                    <Select
                      showSearch={true}
                      allowClear={true}
                      mode="multiple"
                      placeholder="Select Product"
                      optionFilterProp="children"
                      onSearch={onSearch}
                      disabled={showProduct}
                      filterOption={(input, option) =>
                        (option!.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      optionLabelProp="label"
                      maxTagCount="responsive"
                    >
                      <Option value="All">Select All</Option>
                      {productData?.map((product: any, index) => {
                        return (
                          <Option
                            key={index}
                            value={`(${product.UPCCode}) ${product.ItemName}`}
                          >{`(${product.UPCCode}) ${product.ItemName}`}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </Card>
              <Card>
                <div>
                  <Button
                    className=""
                    onClick={() => {
                      setOfficeModal(true);
                      setOfficeList([]);
                    }}
                    disabled={officeSelectMode == "region"}
                  >
                    Quick Office Entry
                  </Button>
                  OR
                  <Form.Item
                    label="Region"
                    name="Region"
                    className="label-text region-field"
                  >
                    <Select
                      showSearch={true}
                      allowClear={true}
                      mode="multiple"
                      placeholder="Select Region"
                      optionFilterProp="children"
                      onSearch={onSearch}
                      style={{ width: "100%" }}
                      onChange={(value) => handleAllData("region", value)}
                      filterOption={(input, option) =>
                        (option!.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      disabled={officeSelectMode == "QuickEntry"}
                      optionLabelProp="label"
                      maxTagCount="responsive"
                    >
                      {regionData?.map((item: any, index) => {
                        return (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="District"
                    name="District"
                    className="label-text"
                  >
                    <Select
                      showSearch={true}
                      allowClear={true}
                      mode="multiple"
                      placeholder="Select District"
                      optionFilterProp="children"
                      onSearch={onSearch}
                      style={{ width: "100%" }}
                      onChange={(value) => handleAllData("district", value)}
                      filterOption={(input, option) =>
                        (option!.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      optionLabelProp="label"
                      maxTagCount="responsive"
                      disabled={!districtData.length}
                    >
                      {districtData?.map((item: any, index) => {
                        return (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Offices"
                    name="Offices"
                    className="label-text region-field"
                    rules={[
                      !officeList.length
                        ? {
                            required: true,
                            message: "Please select atleast one office",
                          }
                        : { required: false, message: "" },
                    ]}
                  >
                    <Select
                      showSearch={true}
                      allowClear={true}
                      mode="multiple"
                      placeholder="Select Offices"
                      optionFilterProp="children"
                      onSearch={onSearch}
                      style={{ width: "72%" }}
                      filterOption={(input, option) =>
                        (option!.children as unknown as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      optionLabelProp="label"
                      maxTagCount="responsive"
                      onChange={(value) => handleAllData("office", value)}
                    >
                      <Option value="All">Select All</Option>
                      {locationData?.map((item: any, index) => {
                        return (
                          <Option key={index} value={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </Card>
              <Card>
                <div className="input-field-lable">
                  <Form.Item
                    label="Replenishment Type"
                    name="ReplenishmentType"
                    className="label-text area-field"
                    // rules={[{ required: true }]}
                  >
                    <Radio.Group
                      options={replenishmentType}
                      onChange={(e) => setRTypeValue(e.target.value)}
                      defaultValue={rTypeValue}
                      value={rTypeValue}
                      optionType="button"
                      buttonStyle="solid"
                      style={{ background: "#003366", color: "#fff" }}
                    />
                  </Form.Item>
                </div>
                {rTypeValue && rTypeValue === "Sales Based" && (
                  <div>
                    <Form.Item
                      label="Last Replenishment Date"
                      name="LastReplenishmentDate"
                      className="label-text area-field"
                    >
                      {" "}
                      <DatePicker
                        showTime={{ format: "hh:mm" }}
                        format={DATE_TIME_FORMAT_REPLENISHMENT}
                        name="LastReplenishmentDate"
                        value={
                          lastReplenishmentDate && moment(lastReplenishmentDate)
                        }
                        disabledDate={(d) => !d || d.isAfter(moment())}
                        placeholder="Last Replenishment Date"
                        onChange={(value: DatePickerProps["value"]) =>
                          setLastReplenishmentDate(
                            moment(value).format(DATE_TIME_FORMAT_REPLENISHMENT)
                          )
                        }
                      />
                    </Form.Item>
                  </div>
                )}

                <div>
                  <Form.Item
                    label="Order Quantity"
                    name="OrderQuantity"
                    className="label-text input-field"
                    rules={[
                      { required: true, message: "Order Quantity is required" },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (parseInt(value) < 0) {
                            return Promise.reject(
                              "Please ensure order quantity is greater than zero"
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input name="OrderQuantity" />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    label="Trigger Quntity"
                    name="TriggerQuntity"
                    className="label-text input-field"
                    rules={[
                      {
                        required: true,
                        message: "Trigger Quntity is required",
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (parseInt(value) < 0) {
                            return Promise.reject(
                              "Please ensure order quantity is greater than zero"
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input name="TriggerQuntity" />
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    name="IsStockedAtDC"
                    className="label-text input-field"
                    valuePropName="checked"
                  >
                    <Checkbox name="IsStockedAtDC" value={true}>
                      Transferred From DC
                    </Checkbox>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item
                    name="IsConsiderBackorder"
                    className="label-text input-field"
                    valuePropName="checked"
                  >
                    <Checkbox name="IsConsiderBackorder" value={true}>
                      Consider Backorder
                    </Checkbox>
                  </Form.Item>
                </div>
              </Card>
            </Form>
          </div>
        </Spin>
        <Modal
          title="Quick Office Entry"
          okText="Ok"
          centered
          width={500}
          confirmLoading={confirmLoading}
          style={{ top: 20 }}
          open={officeModal}
          onOk={handleOfficeList}
          onCancel={() => {
            setOfficeModal(false);
            setOfficeList([]);
          }}
        >
          <span>
            <TextArea
              onChange={(e) => setOfficeList(e.target.value)}
              value={officeList}
              rows={9}
            ></TextArea>
          </span>
        </Modal>
      </Drawer>
    </>
  );
}

export default AddPreferences;

import { Descriptions, Divider, Form, Modal, Spin } from "antd";
import TableFilter from "src/components/common/TableFilter/TableFilter";
import Breadcrumb from "src/components/common/Breadcrumb/Breadcrumb";
import Button from "src/components/common/Button/Button";
import { useCallback, useEffect, useState } from "react";
import filterColumns from "./filterColumns";
import { DATE_FORMAT } from "src/util/columns";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import KitProcessForm from "./KitProcessForm";
import OfficeSelection from "./OfficeSelection";
import Preview from "./Preview";
import {
  showErrorNotification,
  showSuccessNotification,
} from "src/util/notifications";
import { savingKitInDraft } from "./sagas";
import { useMsal } from "@azure/msal-react";

function ProcessNewKit() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const { accounts }: any = useMsal();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  let memoizeDate = moment().format(DATE_FORMAT);
  const [PODate, setPODate] = useState<any>(memoizeDate);
  const location = useLocation();
  const kitData: any = location.state;
  const [frameData, setFrameData] = useState(kitData?.Frames || []);
  const [cancelModal, setCancelModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [selectedOffices, setSelectedOffices] = useState<any[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<any>();
  const [selectedKit, setSelectedKit] = useState<any>();

  const addDrawer = (e: React.MouseEvent<HTMLButtonElement>) => {
    setVisible(true);
  };
  
  useEffect(() => {
    if (kitData) {
      form.setFieldsValue({
        Kit: kitData?.KitName,
        Vendor: kitData?.VendorId,
        PODate:
          kitData?.PODate !== null && kitData?.PODate !== undefined
            ? moment(kitData?.PODate)
            : moment(PODate),
        VendorReferenceNumber: kitData?.VendorReferenceNumber,
        Description: kitData?.Description,
      });
    }
    setSelectedVendor(kitData?.VendorId);
    setSelectedKit(kitData?.KitId);
    kitData?.OfficeData?.map((item: any) => {
      const found = selectedOffices?.some(
        (el: any) => el.LocationNumber === item
      );
      if (!found) {
        selectedOffices.push({ LocationNumber: item });
      }
    });
    const sortedOffice = selectedOffices?.sort((a: any, b: any) => {
      return a.LocationNumber.localeCompare(b.LocationNumber, "en", {
        numeric: true,
      });
    });
    setSelectedOffices(sortedOffice);
  }, [kitData]);

  const handleCancel = () => {
    setVisible(false);
    setOpen(false);
    form.resetFields();
    history.push("/kitProcessing");
  };

  const handleSave = async (type: any) => {
    const row = (await form.validateFields()) as any;
    setSaveModal(true);
  };
  const onSave = async (type: any) => {
    const row = (await form.validateFields()) as any;
    setConfirmLoading(true);
    setSaveModal(false);
    try {
      const fieldData = form.getFieldsValue();
      const payload = {
        KitId: kitData ? kitData?.KitId : fieldData?.Kit,
        VendorId: kitData ? kitData?.VendorId : fieldData?.Vendor,
        PODate: PODate,
        ModifiedDate: PODate,
        OfficeData: selectedOffices?.map((item: any) => item.LocationNumber),
        Status: type === "draft" ? 2 : 4,
        Description: fieldData?.Description,
        VendorReferenceNumber: fieldData?.VendorReferenceNumber,
        CreatedBy: accounts[0]?.username,
      };
      savingKitInDraft(payload)
        .then((res: any) => {
          showSuccessNotification(res);
          handleCancel();
          setConfirmLoading(false);
        })
        .catch((error: any) => {
          showErrorNotification(error?.response?.data?.error?.messages[0]);
          setConfirmLoading(false);
        });
    } catch (error: any) {
      showErrorNotification(error?.response?.data?.error?.messages[0]);
      setConfirmLoading(false);
    }
  };
  return (
    <>
      <Spin spinning={confirmLoading}>
        <Divider />
        <Breadcrumb>
          <span onClick={() => history.push("/kitProcessing")}>
            Kit Processing
          </span>
          <span>Process New Kit</span>
        </Breadcrumb>
        <TableFilter>
          <div className="form align-items-end flex"></div>
          <div className="d-flex justify-content-end">
            <Button
              size="middle"
              style={{ marginRight: "10px" }}
              onClick={addDrawer}
              disabled={!selectedOffices.length || !selectedKit}
              ant-click-animating-without-extra-node="true"
            >
              {" "}
              Preview Results
            </Button>
            <Button
              size="middle"
              style={{ marginRight: "10px" }}
              onClick={() => onSave("draft")}
              disabled={!selectedOffices.length || !selectedKit}
              ant-click-animating-without-extra-node="true"
            >
              Save Draft
            </Button>
            <Button
              size="middle"
              style={{ marginRight: "10px" }}
              disabled={!selectedOffices.length || !selectedKit}
              onClick={() => handleSave("process")}
              ant-click-animating-without-extra-node="true"
            >
              Process Kit
            </Button>
            <Button
              size="middle"
              style={{ marginRight: "10px" }}
              onClick={() => setCancelModal(true)}
              ant-click-animating-without-extra-node="true"
            >
              Cancel
            </Button>
          </div>
        </TableFilter>
        <KitProcessForm
          form={form}
          PODate={PODate}
          setPODate={setPODate}
          selectedVendor={selectedVendor}
          setSelectedVendor={setSelectedVendor}
          kitData={kitData}
          setSelectedKit={setSelectedKit}
          selectedKit={selectedKit}
          setSelectedOffices={setSelectedOffices}
        />
        <OfficeSelection
          selectedOffices={selectedOffices}
          setSelectedOffices={setSelectedOffices}
          kitId={selectedKit}
          vendorName={selectedVendor}
        />
        <Preview
          visible={visible}
          setVisibleStatus={setVisible}
          kitId={selectedKit}
          selectedOffices={selectedOffices}
          poDate={PODate}
        />
        <Modal
          title="Cancel Confirmation"
          okText="Ok"
          centered
          confirmLoading={confirmLoading}
          style={{ top: 20 }}
          open={cancelModal}
          onOk={handleCancel}
          onCancel={() => setCancelModal(false)}
        >
          <span>Are you sure you wish to cancel this kit?</span>
        </Modal>
        <Modal
          title="Save Confirmation"
          okText="Ok"
          centered
          confirmLoading={confirmLoading}
          style={{ top: 20 }}
          open={saveModal}
          onOk={() => onSave("Process")}
          onCancel={() => setSaveModal(false)}
        >
          <span>
            Are you sure you want to process this kit? Have you previewed the
            results prior and confirmed the results are expected? This can’t be
            undone.
          </span>
        </Modal>
      </Spin>
    </>
  );
}
export default ProcessNewKit;

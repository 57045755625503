import columns from '../../util/columns';
import { ACTION, GenericObject } from '../../util/utils';
import useData from './useData';
import useRowSelection from './useRowSelection';

function useTableProps(moduleKey?: string, except: string[] = [], rowKey = 'id', columns = [], tableData = []) {
    const [rowSelectionProps, selectedRow] = useRowSelection();
    const { loading, data } = useData(`${moduleKey}${ACTION.STATE_KEY}`);

    let tableProps: any = {
        rowKey: rowKey,
        rowSelection: {
            type: "checkbox",
            selectedRowKeys: selectedRow.map((row: any) => row[rowKey]),
            ...rowSelectionProps,
        },
        columns: columns,
        dataSource: tableData?.length > 0 ? tableData : data,
        loading: loading,
        selectedRow
    }

    if (except.length) {
        except.forEach((key: string) => {
            delete tableProps[key];
        })
    }

    return tableProps;
}

export default useTableProps
import React, { useState } from 'react'

function useRowSelection(defaultSelected = []) {
  const [selectedRow, setSelectedRow] = useState<any>(defaultSelected);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedRow(selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      name: record.OfficeNum,
    }),
    clearSelection:()=>{
      setSelectedRow([])
    }
  };

  return [rowSelection, selectedRow]
}

export default useRowSelection
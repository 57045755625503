import React from 'react'
import './../../../scss/common/table_filter.scss';
interface Props{
    children: React.ReactNode
}
function TableFilter(props:Props) {
    const {children} = props;
  return (
    <div className='table-filter-wrapper'>{children}</div>
  )
}

export default TableFilter
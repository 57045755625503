import { ACTION, GenericObject, actionKeyGenerate } from "src/util/utils";

export const MODULE_KEY = "FRAME_ARRANGER_QTY";
export const ASSORTMENT_SKU_MODULE_KEY = "ASSORTMENT_SKU";
export const IMPORT_SKU_FILE = "IMPORT_SKU_FILE";
export const IMPORT_FILE_SUCCESS = "IMPORT_FILE_SUCCESS";
export const IMPORT_FILE_FAILED = "IMPORT_FILE_FAILED";
export const ASSORTMENT_IMPORT_FILE = "ASSORTMENT_IMPORT_FILE";
export const ASSORTMENT_IMPORT_FILE_SUCCESS = "ASSORTMENT_IMPORT_FILE_SUCCESS";
export const ASSORTMENT_IMPORT_FILE_FAILED = "ASSORTMENT_IMPORT_FILE_FAILED";

export const CLEAN_IMPORT_LAYOUT = "CLEAN_IMPORT_LAYOUT";
export const UPDATE_VALIDATED_IMPORT = "UPDATE_VALIDATED_IMPORT";
export const UPDATE_VALIDATED_IMPORT_SUCCESS =
  "UPDATE_VALIDATED_IMPORT_SUCCESS";

export const KEYS: GenericObject = actionKeyGenerate(MODULE_KEY);
export const ASSORTMENT_SKU_KEYS: GenericObject = actionKeyGenerate(
  ASSORTMENT_SKU_MODULE_KEY
);

export const FRAME_ARRANGER_QTY_FETCH = KEYS[MODULE_KEY + ACTION.FETCH];
export const ASSORTMENT_SKU_FETCH =
  ASSORTMENT_SKU_KEYS[ASSORTMENT_SKU_MODULE_KEY + ACTION.FETCH];

export const assortmentImportFile = (payload: any) => ({
  type: ASSORTMENT_IMPORT_FILE,
  payload,
});

export const assortmentImportFileSuccess = (payload: any) => ({
  type: ASSORTMENT_IMPORT_FILE_SUCCESS,
  payload,
});

export const assortmentImportFileFailed = (payload: any) => ({
  type: ASSORTMENT_IMPORT_FILE_FAILED,
  payload,
});

  
export const fetchFrameArrangerQty = (payload: any) => ({
  type: FRAME_ARRANGER_QTY_FETCH,
  payload,
});

export const fetchFrameArrangerQtySuccess = (payload: any) => ({
  type: KEYS[MODULE_KEY + ACTION.SUCCESS],
  payload,
});

export const fetchFrameArrangerQtyFailed = (payload: any) => ({
  type: KEYS[MODULE_KEY + ACTION.FAILED],
  payload,
});
export const fetchAssortmentSku = (payload: any) => ({
  type: ASSORTMENT_SKU_FETCH,
  payload,
});

export const fetchAssortmentSkuSuccess = (payload: any) => ({
  type: ASSORTMENT_SKU_KEYS[ASSORTMENT_SKU_MODULE_KEY + ACTION.SUCCESS],
  payload,
});

export const fetchAssortmentSkuFailed = (payload: any) => ({
  type: ASSORTMENT_SKU_KEYS[ASSORTMENT_SKU_MODULE_KEY + ACTION.FAILED],
  payload,
});

export const importSKUFile = (payload: any) => ({
  type: IMPORT_SKU_FILE,
  payload,
});

export const importFileSuccess = (payload: any) => ({
  type: IMPORT_FILE_SUCCESS,
  payload,
});

export const importFileFailed = (payload: any) => ({
  type: IMPORT_FILE_FAILED,
  payload,
});

export const cleanImportLayout = () => ({
  type: CLEAN_IMPORT_LAYOUT,
});

export const updateValidatedImport = (
  payload: any,
  actionType: any,
  flag: any
) => ({
  type: UPDATE_VALIDATED_IMPORT,
  payload,
  actionType,
  flag,
});

import { notification } from 'antd';

export const setNotificationsConfig = () => {
  notification.config({
    top: 50,
    duration: 2,
    maxCount: 1,
  });
};

export const showSuccessNotification = (text = 'Updated successfully') => {
  notification.success({
    key: "success",
    type: "success",
    message: text,
    placement:"topRight",
  });
};

export const showErrorNotification = (text?: any, e?: any) => {
  if (e?.response?.status === 401) {
    notification.error({
      key:"error401",
      message: 'Error',
      description: 'Your session has expired, please re-log in',
      duration: 0,
    });
  } else if (e?.response?.status === 404) {
    notification.error({
      key:"error404",
      type: "error",
      message: text,
      placement:"topRight",
    });
  }  else if (e?.response?.status === 500) {
    notification.error({
      key:"error500",
      type: "error",
      message: text,
      placement:"topRight",
    });
  } else if (e?.response?.status === 400) {
    notification.error({
      key:"error400",
      type: "error",
      message: text,
      placement:"topRight",
    });
  }else {
    notification.error({
      key:"error",
      type: "error",
      message: text || 'Something went wrong, please try again',
      placement:"topRight",
    });
  }
};

export const showWarningNotification = (text = '') => {
  notification.warn({
    key:"warning",
    type: 'warning',
    message: text,
    duration: 0,
    placement:"topRight",
  });
};

import { actionKeyGenerate, ACTION, GenericObject } from "../../util/utils";

export const MODULE_KEY = "VENDORS";

export const KEYS: GenericObject = actionKeyGenerate(MODULE_KEY);

export const VENDORS_FETCH = KEYS[MODULE_KEY + ACTION.FETCH]


export const fetchVendors = (payload: any) => ({
    type: VENDORS_FETCH,
    payload,
  });
  
  export const fetchVendorsSuccess = (payload: any) => ({
    type: KEYS[MODULE_KEY + ACTION.SUCCESS],
    payload,
  });
  
  export const fetchVendorsFailed = (payload: any) => ({
    type: KEYS[MODULE_KEY + ACTION.FAILED],
    payload,
  });
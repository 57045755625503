import { call, put } from 'redux-saga/effects';
import { fetchPendingOrderSuccess, fetchVendorOrderSuccess } from './actions';
import { default as api } from '../../util/api';
import { showErrorNotification } from '../../util/notifications'
import { VENDOR_ORDER_URL } from '../../util/constants/api.constants';
import { toQueryParam } from '../../util/utils';


export function fetchVendorOrderList(payload: any) {
  let url = toQueryParam(VENDOR_ORDER_URL.VENDOR_ORDER_LIST, payload);
  return api.get(url, {});
}

export function fetchOrderItems (payload: any) {
  let url = VENDOR_ORDER_URL.VENDOR_ORDER_ITEMS+payload.orderNumber+"/items";
  return api.get(url, {});
}

export function* fetchVendorOrder({ payload }: any): Generator {
  try {
    let url = VENDOR_ORDER_URL.VENDOR_ORDER_ITEMS+payload.orderNumber+"/items";
    const data: any = yield call(api.get, url);
    yield put(fetchVendorOrderSuccess(data));
  } catch (e: any) {
    showErrorNotification(null, e);
  }
}

export function saveVendorOrderItems(payload: any) {
  let url = `${VENDOR_ORDER_URL.SAVE_ORDER_ITEMS}`;
  return api.put(url, payload);
}

export function* fetchPendingOrders({ payload }: any): Generator {
  try {
    let url = toQueryParam(VENDOR_ORDER_URL.PENDING_ORDER_LIST, payload);
    const data: any = yield call(api.get, url);
    yield put(fetchPendingOrderSuccess(data));
  } catch (e: any) {
    showErrorNotification(null, e);
  }
}

export function fetchPendingOrderItems (payload: any) {
  let url = toQueryParam(VENDOR_ORDER_URL.PENDING_ORDER_LIST, payload);
  return api.get(url, {});
}

export function cancelVendorOrderItems(payload: any) {
  let url = `${VENDOR_ORDER_URL.CANCEL_ORDER_ITEMS}`;
  return api.put(url, payload);
}



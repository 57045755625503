import { call, put } from "redux-saga/effects";
import { showErrorNotification } from "src/util/notifications";
import api from "src/util/api";
import {
  EXPRESS_OFFICE_URL,
  FEDEX_URL,
} from "src/util/constants/api.constants";
import { fetchExpressOfficeFailed, fetchExpressOfficeSuccess } from "./actions";

export function* fetchExpressOffice({ payload }: any): Generator {
  try {
    let url = EXPRESS_OFFICE_URL.EXPRESS_OFFICE_LIST;
    const data: any = yield call(api.get, url);
    yield put(fetchExpressOfficeSuccess(data));
  } catch (e: any) {
    yield put(fetchExpressOfficeFailed({ message: e }));
    showErrorNotification(null, e);
  }
}

export function saveExpressOffice(payload: any) {
  let url = EXPRESS_OFFICE_URL.ADD_OFFFICE;
  return api.post(url, payload);
}

export function deleteOffice(payload: any) {
  let url = EXPRESS_OFFICE_URL.DELETE_OFFICE;
  return api.delete(url, payload);
}

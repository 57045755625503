import { actionKeyGenerate, ACTION, GenericObject } from "../../util/utils";

export const MODULE_KEY = "VIEW_ORDER";
export const DAILY_REPORT_MODULE_KEY = "DAILY_REPORT";
export const VENDOR_EXLUSION_MODULE_KEY = "VENDOR_EXLUSION";

export const KEYS: GenericObject = actionKeyGenerate(MODULE_KEY);
export const DAILY_REPORT_KEYS: GenericObject = actionKeyGenerate(DAILY_REPORT_MODULE_KEY);
export const VENDOR_EXLUSION_KEYS =actionKeyGenerate(VENDOR_EXLUSION_MODULE_KEY);

export const ORDER_STATUS_FETCH = KEYS[MODULE_KEY + ACTION.FETCH]
export const DAILY_REPORT_FETCH = DAILY_REPORT_KEYS[DAILY_REPORT_MODULE_KEY + ACTION.FETCH]
export const VENDOR_EXLUSION_FETCH = VENDOR_EXLUSION_KEYS[VENDOR_EXLUSION_MODULE_KEY + ACTION.FETCH];


export const fetchOrderStatus = (payload: any) => ({
  type: ORDER_STATUS_FETCH,
  payload,
});

export const fetchOrderStatusSuccess = (payload: any) => ({
  type: KEYS[MODULE_KEY + ACTION.SUCCESS],
  payload,
});

export const fetchOrderStatusFailed = (payload: any) => ({
  type: KEYS[MODULE_KEY + ACTION.FAILED],
  payload,
});


export const fetchDailyReport = (payload: any) => ({
  type: DAILY_REPORT_FETCH,
  payload,
});

export const fetchDailyReportSuccess = (payload: any) => ({
  type: DAILY_REPORT_KEYS[DAILY_REPORT_MODULE_KEY + ACTION.SUCCESS],
  payload,
});

export const fetchDailyReportFailed = (payload: any) => ({
  type: DAILY_REPORT_KEYS[DAILY_REPORT_MODULE_KEY + ACTION.FAILED],
  payload,
});

export const fetchVendorExclusion = (payload: any) => ({
  type: ORDER_STATUS_FETCH,
  payload,
});

export const fetchVendorExclusionSuccess = (payload: any) => ({
  type: KEYS[MODULE_KEY + ACTION.SUCCESS],
  payload,
});

export const fetchVendorExclusionFailed = (payload: any) => ({
  type: KEYS[MODULE_KEY + ACTION.FAILED],
  payload,
});
import { Button as AntButton } from 'antd'
import React from 'react'

interface Props {
    children: React.ReactNode
    icon?: React.ReactNode
    className?: string
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    id?: string
    type?: "link" | "text" | "primary" | "default" | "ghost" | "dashed" | undefined
    disabled?: boolean
    size?: 'small' | 'middle' | 'large' | undefined;
    danger?: boolean;
    style?: any;
    htmlType?: any;
}

function Button({ children, className = "", ...props}: Props) {
    return (
        <section className='btn-wrapper'>
            <AntButton
                size="small"
                className={`${className}`}
                {...props}
            >
                {children}
            </AntButton>
        </section>
    )
}

export default Button
import React from 'react'
import { Breadcrumb as AntBreadcrumb } from 'antd';
import { useHistory } from 'react-router-dom';
import './breadcrumb.scss';

function Breadcrumb({ children }: any) {
    const history = useHistory();

    return (
        <AntBreadcrumb className='breadcrumb-wrapper'>
            <AntBreadcrumb.Item onClick={() => history.push("/dashboard")} >Home</AntBreadcrumb.Item>
            {Array.isArray(children) ? children.map((child: any) => {
                return <AntBreadcrumb.Item >{child}</AntBreadcrumb.Item>
            }) : <AntBreadcrumb.Item >{children}</AntBreadcrumb.Item>}
        </AntBreadcrumb>
    )
}

export default Breadcrumb
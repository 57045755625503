import { call, put } from "redux-saga/effects";
import { validateAcuityFrameImportSuccess, validateAcuityFrameImportFailed } from "./actions";
import { showErrorNotification, showSuccessNotification } from "src/util/notifications";
import api from "src/util/api";
import { FRAME_ARRANGER_QTY_URL, PRODUCT_PLANOGRAM_URL } from "src/util/constants/api.constants";

export function * validateActuityFrameImport({ payload, actionType }: any): Generator {
  try {
    let url = `${PRODUCT_PLANOGRAM_URL.VALIDATE_ACUITY_FRAME_IMPORT}`;
    const formdata = new FormData();
    formdata.append('file', payload.file);
    const data: any = yield call(api.post, url, formdata);
    yield put(validateAcuityFrameImportSuccess(data));
  } catch (e: any) {
    yield put(validateAcuityFrameImportFailed(e.response?.data));
  }
}

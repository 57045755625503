import { ACTION } from "src/util/utils";
import {
  KEYS, MODULE_KEY, PRODUCT_PLANOGRAM_IMPORT_FILE, PRODUCT_PLANOGRAM_IMPORT_FILE_FAILED, PRODUCT_PLANOGRAM_IMPORT_FILE_SUCCESS,
 PRODUCT_PLANOGRAM_UPDATE_VALIDATED_IMPORT,PRODUCT_PLANOGRAM_UPDATE_VALIDATED_IMPORT_SUCCESS, PRODUCT_PLANOGRAM_UPDATE_VALIDATED_IMPORT_FAILED, PRODUCT_PLANOGRAM_CLEAN_IMPORT_LAYOUT} from "./actions";

export const REDUCER_KEY = KEYS[MODULE_KEY + ACTION.STATE_KEY]

export interface IProductPlanogramMappingState {
  loading: boolean;
  error: any;
  data: [];
  importLoading: boolean;
  importFailed: boolean;
  importValidationData: any[];
}

const defaultState: IProductPlanogramMappingState = {
  loading: false,
  error: null,
  data: [],
  importLoading: false,
  importFailed: false,
  importValidationData: [],
};

export const productPlanogramMappingReducer = (
  state = defaultState,
  { type, payload }: any
) => {
  
  switch (type) {
    case KEYS[MODULE_KEY + ACTION.FETCH]:
      return {
        ...state,
        loading: true,
      };
      
    case KEYS[MODULE_KEY + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        data: [...payload],
      };
    case KEYS[MODULE_KEY + ACTION.FAILED]:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case PRODUCT_PLANOGRAM_IMPORT_FILE: {
      return {
        ...state,
        importLoading: true,
      };
    }
    case PRODUCT_PLANOGRAM_IMPORT_FILE_SUCCESS: {
      return {
        ...state,
        importLoading: false,
        importFailed: false,
        importValidationData: payload,
      };
    }
    case PRODUCT_PLANOGRAM_IMPORT_FILE_FAILED: {
      return {
        ...state,
        importLoading: false,
        importFailed: true,
        importValidationData: payload,
      };
    }
    case PRODUCT_PLANOGRAM_UPDATE_VALIDATED_IMPORT: {
      return {
        ...state,
        importLoading: true,
      };
    }
    case PRODUCT_PLANOGRAM_UPDATE_VALIDATED_IMPORT_SUCCESS:
    case PRODUCT_PLANOGRAM_UPDATE_VALIDATED_IMPORT_FAILED: {
      return {
        ...state,
        importLoading: false,
      };
    }
    case PRODUCT_PLANOGRAM_CLEAN_IMPORT_LAYOUT: {
      return {
        ...state,
        importLoading: false,
        importFailed: false,
        importValidationData: [],
      };
    }

    default:
      return state;
  }
};


import { ACTION } from "../../util/utils";
import { KEYS, MODULE_KEY, PENDING_ORDER_KEYS, PENDING_ORDER_MODULE_KEY } from "./actions";

export const REDUCER_KEY = KEYS[MODULE_KEY + ACTION.STATE_KEY]
export const PENDING_ORDER_REDUCER_KEY = PENDING_ORDER_KEYS[PENDING_ORDER_MODULE_KEY +  ACTION.STATE_KEY]

export interface IVendorOrderState {
  loading: boolean;
  error: any;
  data: [];
}

const defaultState: IVendorOrderState = {
  loading: false,
  error: null,
  data: [],
};

export interface IPendingOrderState {
  loading: boolean;
  error: any;
  data: [];
}

const pendingDefaultState: IPendingOrderState = {
  loading: false,
  error: null,
  data: [],
};

export const vendorOrderReducer = (
  state = defaultState,
  { type, payload }: any
) => {
  switch (type) {
    case KEYS[MODULE_KEY + ACTION.FETCH]:
      return {
        ...state,
        loading: true,
      };
      
    case KEYS[MODULE_KEY + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        data: [...payload],
      };
    case KEYS[MODULE_KEY + ACTION.FAILED]:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export const pendingOrderReducer = (
  state = pendingDefaultState,
  { type, payload }: any
) => {
  switch (type) {
    case PENDING_ORDER_KEYS[PENDING_ORDER_MODULE_KEY + ACTION.FETCH]:
      return {
        ...state,
        loading: true,
      };
      
    case PENDING_ORDER_KEYS[PENDING_ORDER_MODULE_KEY + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        data: [...payload],
      };
    case PENDING_ORDER_KEYS[PENDING_ORDER_MODULE_KEY + ACTION.FAILED]:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

import { ACTION } from "src/util/utils";
import { KEYS, MODULE_KEY, AVAILABLE_OFFICE_KEYS, AVAILABLE_OFFICE_MODULE_KEY, SELECTED_OFFICE_KEYS, SELECTED_OFFICE_MODULE_KEY,  } from "./actions";

export const REDUCER_KEY = KEYS[MODULE_KEY + ACTION.STATE_KEY];
export const AVAILABLE_OFFICE_REDUCER_KEY = AVAILABLE_OFFICE_KEYS[AVAILABLE_OFFICE_MODULE_KEY + ACTION.STATE_KEY];
export const SELECTED_OFFICE_REDUCER_KEY = SELECTED_OFFICE_KEYS[SELECTED_OFFICE_MODULE_KEY + ACTION.STATE_KEY];

export interface IKitProcessingState {
  loading: boolean;
  error: any;
  data: [];
}

const defaultState: IKitProcessingState = {
  loading: false,
  error: null,
  data: [],
};

export const kitProcessingReducer = (
  state = defaultState,
  { type, payload }: any
) => {
  switch (type) {
    case KEYS[MODULE_KEY + ACTION.FETCH]:
      return {
        ...state,
        loading: true,
      };

    case KEYS[MODULE_KEY + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        data: [...payload],
      };
    case KEYS[MODULE_KEY + ACTION.FAILED]:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export interface IOfficeState {
  loading: boolean;
  error: any;
  data: [];
}

const officeDefaultState: IOfficeState = {
  loading: false,
  error: null,
  data: [],
};

export const availableOfficeReducer = (
  state = officeDefaultState,
  { type, payload }: any
) => {
  switch (type) {
    case AVAILABLE_OFFICE_KEYS[AVAILABLE_OFFICE_MODULE_KEY + ACTION.FETCH]:
      return {
        ...state,
        loading: true,
      };

    case AVAILABLE_OFFICE_KEYS[AVAILABLE_OFFICE_MODULE_KEY + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        data: [...payload],
      };
    case AVAILABLE_OFFICE_KEYS[AVAILABLE_OFFICE_MODULE_KEY + ACTION.FAILED]:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};

export interface IselectedOfficeState {
  loading: boolean;
  error: any;
  data: [];
}

const selectedOfficeDefaultState: IselectedOfficeState = {
  loading: false,
  error: null,
  data: [],
};

export const selectedOfficeReducer = (
  state = selectedOfficeDefaultState,
  { type, payload }: any
) => {
  switch (type) {
    case SELECTED_OFFICE_KEYS[SELECTED_OFFICE_MODULE_KEY + ACTION.FETCH]:
      return {
        ...state,
        loading: true,
      };

    case SELECTED_OFFICE_KEYS[SELECTED_OFFICE_MODULE_KEY + ACTION.SUCCESS]:
      return {
        ...state,
        loading: false,
        data: [...payload],
      };
    case SELECTED_OFFICE_KEYS[SELECTED_OFFICE_MODULE_KEY + ACTION.FAILED]:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    default:
      return state;
  }
};
